import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import Project from 'src/app/models/Project';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  private path = "project";

  constructor(private apiService: ApiService) { }

  getAll() {
    return this.apiService.getRequest(this.path)
  }

  getByProjectId(id: string) {
    return this.apiService.getRequest(this.path + "/" + id)
  }

  async getProjectsByUserId(user_id: string) {
    const response: any = await firstValueFrom(this.apiService.getRequest(`${this.path}/byUser/${user_id}`));
    return response.projects.map((project: any) => new Project(project));
  }

  uploadMasterFile(project: Project, master_file: File) {
    const formData = new FormData();
    console.log("project", project);
    console.log("master_file", master_file);
    const blob = new Blob([master_file], { type: master_file.type })
    formData.append("project_id", project.id);
    formData.append("filename", master_file.name);
    formData.append("file", blob);
    return this.apiService.postRequestFormData("file/upload-master-file", formData);
  }

  create(project: Project) {
    return this.apiService.postRequest(this.path, { project: project });
  }

  update(project: Project) {
    return this.apiService.putRequest(this.path, { project: { id: project.id, name: project.name, info: project.info, contact_name: project.contact_name, contact_email: project.contact_email, contact_phone: project.contact_phone, master_file_id: project.master_file_id, sites: project.sites } });
  }

  delete(projectId: string) {
    return this.apiService.deleteRequest(this.path + `/${projectId}`);
  }

  isProjectExists(project_name: string) {
    return this.apiService.getRequest(this.path + `/is-exists/${project_name}`);
  }

  retrieveAllFiles(projectId: string) {
    return this.apiService.getRequest(this.path + `/retrieve/${projectId}`);
  }
}
