<div class="mask-popup" *ngIf="siteForm" (click)="closePopup()">
    <form class="content"  [formGroup]="siteForm" (ngSubmit)="onSubmit()" (click)="$event.stopPropagation();">
        <div class="wrap-title">
            <h1 *ngIf="type == 'create'">Create Site</h1>
            <h1 *ngIf="type == 'edit'">Edit Site: {{site.name}}</h1>
            <img alt="close" src="assets/Icons/close.png" (click)="closePopup()" />
        </div>
        <div class="wrap-field">
            <div class="label">Name<span class="error">*</span></div>
                <input type="text" placeholder="Type site name" formControlName="name"  id="name" required (keyup)="onTypingSiteName()"/>
                <span class="error"
                *ngIf="siteFormControl['name'].touched  && siteFormControl['name'].errors?.['required']">
                Name is required
            </span>
        </div>

        <div class="wrap-field">
            <div class="label">Contact Name<span class="error">*</span></div>
            <input type="text" placeholder="Type manager name"
                formControlName="manager_name" id="manager_name" required />
                <span class="error"
                *ngIf="siteFormControl['manager_name'].touched  && siteFormControl['manager_name'].errors?.['required']">
                Manager Name is required
            </span>
        </div>
        <div class="wrap-field">
            <div class="label">Contact Phone<span class="error">*</span></div>
            <input type="text"  placeholder="Type manager phone"
                formControlName="manager_phone"  id="manager_phone" required />
                <span class="error"
                *ngIf="siteFormControl['manager_phone'].touched  && siteFormControl['manager_phone'].errors?.['required']">
                Manager phone is required
            </span>
            <span class="error" *ngIf="siteFormControl['manager_phone'].errors?.['pattern']">Please provide a valid phone number</span>
        </div>
        <div class="wrap-field">
            <div class="label">Contact Email<span class="error">*</span></div>
            <input type="email" placeholder="Type manager email"
                formControlName="manager_email"  id="manager_email" required />
                <span class="error"
                *ngIf="siteFormControl['manager_email'].touched  && siteFormControl['manager_email'].errors?.['required']">
                Manager Email is required
            </span>
            <span class="error" *ngIf="siteFormControl['manager_email'].errors?.['pattern']">Please provide a valid email address</span>
        </div>
        <div class="wrap-field">
            <div class="label">SFTP Server Credentials</div>
            <div class="label">Username</div>
            <input type="text" formControlName="sftp_username"/>
            <div class="label password">Password</div>
            <input type="password" formControlName="sftp_password"/>
        </div>
        <div class="validations-errors">
            <span class="error" *ngIf="showErrorSiteNameAlreadyExists"> The site name already exists </span>
        </div>
        <div class="d-flex ">
            <button *ngIf="type == 'create'" type="submit">Create</button>
            <button *ngIf="type == 'edit'" type="submit">Update</button>
            <button class="white" (click)="closePopup()">Cancel</button>
        </div>
    </form>
</div>