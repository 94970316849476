import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserManagementService } from '../services/userManagementService/user-management.service';
import User from '../models/User';
import { first, map, tap } from 'rxjs';

export const userGuard: CanActivateFn = (route, state) => {
    const userService = inject(UserManagementService);
    const router = inject(Router);
    
    return userService.whenLoggedIn().pipe(
        first(), // auto unsubscribe after the first value is received
        tap((user?: User): void => {
            if (!user) {
                // redirect to login page
                router.navigate(['login'], { queryParams: route.queryParams });
            }
        }),
        map((user?: User) => !!user) // Maps the permission state to a boolean value because CanActivate should return a boolean
    );
};
