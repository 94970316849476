<div class="mask-popup agent-popup">
    <form class="content" [formGroup]="agentForm" *ngIf="agentForm" (ngSubmit)="onSubmit()"
        (click)="$event.stopPropagation();">
        <div class="wrap-title">
            <h1>{{ agent.id ? 'Update' : 'Create' }} Agent</h1>
            <img alt="close" src="assets/Icons/close.png" (click)="closePopup()" />
        </div>
        <div class="wrap-field">
            <div class="label">Agent Name<span class="error">*</span></div>
            <input type="text" placeholder="Type agent name" formControlName="name" id="name" required />
            <span class="error"
                *ngIf="agentFormControl['name'].touched  && agentFormControl['name'].errors?.['required']">
                Agent name is required
            </span>
        </div>
        <div class="wrap-field">
            <div class="label">Description<span class="error">*</span></div>
            <textarea placeholder="Type description" formControlName="explanation" id="explanation" required></textarea>
            <span class="error"
                *ngIf="agentFormControl['explanation'].touched  && agentFormControl['explanation'].errors">
                Description is required
            </span>
        </div>
        <div class="wrap-field">
            <div class="label">Project</div>
            <mat-form-field>
                <mat-select formControlName="project_id">
                    <mat-option (click)="agentForm.controls['project_id'].reset()"></mat-option>
                    <mat-option *ngFor="let project of projectsList" [value]="project.id">{{project.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="wrap-field">
            <div class="label">OS<span class="error">*</span></div>
            <input type="text" placeholder="Type OS" formControlName="os" id="os" required />
            <span class="error" *ngIf="agentFormControl['os'].touched  && agentFormControl['os'].errors?.['required']">
                OS is required
            </span>
        </div>
        <div class="wrap-field">
            <div class="label">Memory<span class="error">*</span></div>
            <input type="text" placeholder="Type memory" formControlName="memory" id="memory" required />
            <span class="error"
                *ngIf="agentFormControl['memory'].touched  && agentFormControl['memory'].errors?.['required']">
                Memory is required
            </span>
        </div>
        <div class="wrap-field">
            <div class="label">Compute<span class="error">*</span></div>
            <input type="text" placeholder="Type compute" formControlName="compute" id="compute" required />
            <span class="error"
                *ngIf="agentFormControl['compute'].touched  && agentFormControl['compute'].errors?.['required']">
                Compute is required
            </span>
        </div>
        <div class="wrap-field">
            <div class="label">Pre requirement software<span class="error">*</span></div>
            <input type="text" placeholder="Type pre requirement software" formControlName="pre_requirement_software"
                id="pre_requirement_software" required />
            <span class="error"
                *ngIf="agentFormControl['pre_requirement_software'].touched  && agentFormControl['pre_requirement_software'].errors?.['required']">
                Pre requirement software is required
            </span>
        </div>
        <div class="wrap-field">
            <div class="label">Libs<span class="error">*</span></div>
            <input type="text" placeholder="Type libs" formControlName="libs" id="libs" required />
            <span class="error"
                *ngIf="agentFormControl['libs'].touched  && agentFormControl['libs'].errors?.['required']">
                Libs is required
            </span>
        </div>
        <div class="wrap-field">
            <div class="label">Expected run time<span class="error">*</span></div>
            <input type="text" placeholder="Type expected run time" formControlName="expected_run_time"
                id="expected_run_time" required />
            <span class="error"
                *ngIf="agentFormControl['expected_run_time'].touched  && agentFormControl['expected_run_time'].errors?.['required']">
                Expected run time is required
            </span>
        </div>
        <div class="wrap-field">
            <div>
                <div class="label">Agent<span class="error">*</span></div>
                <div class="d-flex wrap-files">
                    <div class="wrap-file">
                        <div class="wrap-input">
                            <input type="file" (change)="updateFile($event, filePurpose.agentWindows)" accept=".exe"/>
                            <div class="upload-input-btn">
                                {{ noAgentWindowsFile ? 'Upload Windows File' : 'Replace Windows File' }} <img
                                    alt="upload" src="assets/Icons/windows-gray.png" />
                            </div>
                        </div>
                        <span class="upload-path">{{filesToUpload[filePurpose.agentWindows]?.name}}</span>
                    </div>
                    <div class="wrap-file">
                        <div class="wrap-input">
                            <input type="file" (change)="updateFile($event, filePurpose.agentLinux)" />
                            <div class="upload-input-btn">
                                {{ noAgentLinuxFile ? 'Upload Linux File' : 'Replace Linux File' }} <img alt="upload"
                                    src="assets/Icons/linux-gray.png" />
                            </div>
                        </div>
                        <span class="upload-path">{{filesToUpload[filePurpose.agentLinux]?.name}}</span>
                    </div>
                    <div class="wrap-file">
                        <div class="wrap-input">
                            <input type="file" (change)="updateFile($event, filePurpose.agentMac)" />
                            <div class="upload-input-btn">
                                {{ noAgentMacFile ? 'Upload File' : 'Replace File' }} <img alt="upload"
                                    src="assets/Icons/apple-logo-gray.png" />
                            </div>
                        </div>
                        <span class="upload-path">{{filesToUpload[filePurpose.agentMac]?.name}}</span>
                    </div>
                </div>
            </div>
            <span class="error"
                *ngIf="agentFormControl[filePurpose.agentLinux].touched && agentFormControl[filePurpose.agentWindows].touched && agentFormControl[filePurpose.agentMac].touched && noAgentFiles">Agent
                file is required</span>
        </div>
        <div class="wrap-field">
            <div>
                <div class="label">User Guide</div>
                <div class="wrap-file">
                    <div class="wrap-input" id="uploadMasterFileDiv">
                        <input type="file" (change)="updateFile($event, filePurpose.userGuide)" accept=".pdf" />
                        <div class="upload-input-btn">
                            {{ noUserGuide ? 'Upload File' : 'Replace File' }} <img alt="upload"
                                src="assets/Icons/upload-gray.png" />
                        </div>
                        <span class="upload-path">{{filesToUpload[filePurpose.userGuide]?.name}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="wrap-field">
            <div>
                <div class="label">Master File</div>
                <div class="wrap-file">
                    <div class="wrap-input" id="uploadMasterFileDiv">
                        <input type="file" (change)="updateFile($event, filePurpose.masterFile)" accept=".csv" />
                        <div class="upload-input-btn"> {{ !agent.master_file && !filesToUpload[filePurpose.masterFile] ? 'Upload File' : 'Replace File' }} <img alt="upload" src="assets/Icons/upload-gray.png" />
                        </div>
                        <span class="upload-path">{{filesToUpload[filePurpose.masterFile]?.name}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="wrap-field">
            <div class="label">Icon</div>
            <div class="wrap-file">
                <div class="wrap-input">
                    <input type="file" (change)="updateFile($event, filePurpose.icon)" accept="image/*" />
                    <div class="upload-input-btn">
                        {{ !agent.icon && !filesToUpload[filePurpose.icon] ? 'Upload File' : 'Replace File' }} <img alt="upload" src="assets/Icons/upload-gray.png" />
                    </div>
                    <span class="upload-path">{{filesToUpload[filePurpose.icon]?.name}}</span>
                </div>
            </div>
        </div>
        <div class="d-flex ">
            <button type="submit">{{ agent.id ? 'Save' : 'Create' }}</button>
            <button class="white" (click)="closePopup()">Cancel</button>
        </div>
    </form>
    <div class="custom-spinner" *ngIf="isLoading">
        <app-spinner></app-spinner>
    </div>
</div>
