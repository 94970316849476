export default class ProjectSite {
    id: string = "";
    site_id: string = "";
    input_file_path: string|null = "";
    link_to_retrieve: string = "";
    site_name: string = "";
    input_file_created_at: Date;

    constructor(data?: any) {
        if (!data) return;
        if (data.id) this.id = data.id;
        if (data.siteId) this.site_id = data.site_id;
        if (data.input_file_path) this.input_file_path = data.input_file_path;
        if (data.link_to_retrieve) this.link_to_retrieve = data.link_to_retrieve;
        if (data.input_file_created_at) this.input_file_created_at = new Date(data.input_file_created_at);
        if (data.site) {
            if (data.site.id) this.site_id = data.site.id;
            if (data.site.name) this.site_name = data.site.name;
        }
    }
}