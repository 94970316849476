import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Amplify } from 'aws-amplify';
import { fetchAuthSession, signIn, signOut, signUp, confirmSignIn } from 'aws-amplify/auth';
import { environment } from 'src/environments/environment';
import { UserManagementService } from '../userManagementService/user-management.service';
import { UserService } from '../user/user.service';
import User from 'src/app/models/User';

export interface IUser {
  email: string;
  password: string;
  showPassword: boolean;
  code: string;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class CognitoAwsService {

  constructor(
    private router: Router,
    private userService: UserService,
    private userManagementService: UserManagementService
  ) {
    Amplify.configure({
      Auth: {
        Cognito: environment.cognito,
      }
    });
    this.setUser();
  }

  async setUser() {
    const session = await fetchAuthSession();
    const email = session?.tokens?.idToken?.payload['email']?.toString();
    const idToken = session?.tokens?.idToken?.toString();
    if (email && idToken) {
			const name = email;
      // create in db
      this.userService.findOrCreateUser(name, email, idToken).then((res: User) => {
        // update UI
        if (res) {
          res.idToken = idToken;
          this.userManagementService.createUser(res);
        } else {
          this.userManagementService.createUser(new User({ email, idToken, name }));
        }
        if (this.router.url.includes('login') || this.router.url === '/') {
          this.router.navigate(['/projects']);
        }
      });
    } else {
			this.userManagementService.createUser();
		}
  }
  
   
  public async signUpOrSignIn(email: string): Promise<any> {
    return signUp({
      username: email,
      password: this.getRandomString(30),
    }).catch((error)=> {
      if (error.message == 'An account with the given email already exists.') {
        return this.signIn(email);
      }
      throw error;
    }).then((res)=> {
      if (res && res.isSignUpComplete == true) {
        return this.signIn(email);
      } else if (res && res.nextStep && res.nextStep.signInStep == 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE') {
        return res;
      } else {
        console.log('signUpOrSignIn response', res);
        throw 'Unexpected sign up or sign in response';
      }
    });
  }

  private getRandomString(bytes: number) {
    const randomValues = new Uint8Array(bytes);
    window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues).map((nr: number) => nr.toString(16).padStart(2, '0')).join('');
  }

  public confirmSignIn(code: string): Promise<any> {
    return confirmSignIn({
      challengeResponse: code,
    }).then((res)=> {
			if (res && res.isSignedIn == true) {
        this.setUser();
      } else {
        console.log('confirmSignIn response', res);
        throw 'Unexpected confirm sign in response';
      }
    });
  }

  private signIn(email: string): Promise<any> {
    return signIn({ 
      username: email, 
      options: {
        authFlowType: 'CUSTOM_WITHOUT_SRP'
      }
    });
  }

  async signOut(): Promise<void> {
    const session = await fetchAuthSession();
    if (session.tokens) {
      await signOut();
      this.userManagementService.createUser();
    }
    this.router.navigate(['/login']);
  }
}
