export default class OutputFile {
    id: number = 0;
    location: string = "";
    run_id: number = 0;

    constructor(data?: any) {
        if (!data) return;
        if (data.id) this.id = data.id;
        if (data.location) this.location = data.location;
        if (data.run_id) this.run_id = data.run_id;
    }
}

export enum OutputType {
    script = "script",
    application = "application",
}