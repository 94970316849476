import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class LambdaSimulatorService {
  path: string = "lambda-simulator";
  constructor(private apiService: ApiService) { }

  invoke(lambda_function: string, num_of_instances: number) {
    return this.apiService.postRequest(this.path, { lambda_function: lambda_function, num_of_instances: num_of_instances });
  }

  getFunctionNames() {
    return this.apiService.getRequest(this.path);
  }
}
