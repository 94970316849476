<app-header></app-header>
<div class="page projects">

    <div class="title">
        <div class="d-flex">
            <h2 *ngIf="projects">Projects | <span class="count">{{ projects.length + ' out of ' + projectsList.length }}</span></h2>
            <app-search *ngIf="projectsList && projectsList.length" [items]="projectsList" [searchKeys]="['id', 'name']"
                (itemsListChange)="onSearchChange($event)"></app-search>
        </div>
        <button (click)="openCreateProjectPopup()">
            + New Project
        </button>
    </div>
    <div class="table-wrap">
        <table *ngIf="projectsList && projectsList?.length">
            <tr>
                <th>Project Id</th>
                <th>Project Name</th>
                <th>Contact Name</th>
                <th>Contact Email</th>
                <th>Contact Phone</th>
                <th></th>
                <th></th>
            </tr>
            <tr *ngFor="let project of projects | paginate: { itemsPerPage: 7, currentPage: numberProjectsPagination }">
                <td (click)="goToProjectPage(project.id)">{{project.id}}</td>
                <td (click)="goToProjectPage(project.id)">{{project.name}}</td>
                <td>{{project.contact_name}}</td>
                <td>{{project.contact_email}}</td>
                <td>{{project.contact_phone}}</td>
                <td (click)="openEditProjectPopup(project.id)"><img src="assets/Icons/editing.svg" /></td>
                <td (click)="openDeleteProjectPopup(project.id)"><img src="assets/Icons/delete.png" /></td>
            </tr>
        </table>
        <pagination-controls (pageChange)="numberProjectsPagination = $event" *ngIf="projects && projects.length > 7"></pagination-controls>
        <div class="empty-wrap" *ngIf="!projectsList || !projectsList.length">
            <h2>You don’t have data projects. Start adding by clicking the button above.</h2>
            <h3>The projects be listed here.</h3>
            <img alt="empty projects list" src="assets/Icons/project-list-empty.png" />
        </div>
    </div>
    <app-new-project *ngIf="showProjectPopup" (closePopupEvent)="closeProjectPopup($event)" [type]="projectPopupType"
        [projectId]="projectId" (downloadMasterFileEvent)="getMasterFileContent($event)"></app-new-project>
    <app-delete-popup *ngIf="showDeleteProjectPopup" (closePopupEvent)="closeDeleteProjectPopup($event)" [title]="'Delete Project'" [content]="contentMessageForDeletePopup"></app-delete-popup>
    <a id="hidden-download-button" hidden [href]="masterFileLink" [download]="masterFileName">
    </a>
</div>
<div class="custom-spinner" *ngIf="isLoading">
    <app-spinner></app-spinner>
</div>