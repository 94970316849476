export default class MasterFile {
    id: string = "";
    location: string = "";
    content: any;

    get name(): string {
        return this.location.substring(this.location.lastIndexOf('/')+1);
    }

    constructor(data?: any) {
        if (!data) return;
        if (data.id) this.id = data.id;
        if (data.location) this.location = data.location;
        if (data.content) this.content = data.content;
    }
}