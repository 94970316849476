export const environment = {
    production: false,
    websocketHost: "https://dev.mdcloneconnect.com/websocket",
    host: "https://dev.mdcloneconnect.com/api",
    staticRequestsHost: "https://dev.mdcloneconnect.com",
    googleServiceAccountEmail: "innovation-google-sheet@nestcc-2a9bf.iam.gserviceaccount.com",
    version: "1.2",
    cognito: {
      userPoolId: 'eu-north-1_5K7krtLi8',
      userPoolClientId: '5f4cutj38qtslm3v8odik1u623',
    },
    ragUrl:"https://chat.dev.mdcloneconnect.com",
    chatModelLLM: "Chat GPT"
  };
  