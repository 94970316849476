import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { first, tap, map } from 'rxjs';
import User, { Role } from '../models/User';
import { UserManagementService } from '../services/userManagementService/user-management.service';

export const noUserGuard: CanActivateFn = (route, state) => {
    const userService = inject(UserManagementService);
    const router = inject(Router);
    
    return userService.whenLoggedIn().pipe(
        first(), // auto unsubscribe after the first value is received
        tap((user?: User): void => {
            // redirect to home page
            if (user && user.role === Role.Admin) {
                router.navigate(['projects'], { queryParams: route.queryParams });
            } else if (user && user.role === Role.User) {
                router.navigate(['user-projects'], { queryParams: route.queryParams });
            }
        }),
        map((user?: User) => !user) // Maps the permission state to a boolean value because CanActivate should return a boolean
    );
};
