import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserManagementService } from '../services/userManagementService/user-management.service';
import User, { Role } from '../models/User';
import { first, map, tap } from 'rxjs';

export const hasProjectGuard: CanActivateFn = (route, state) => {
    const userManagementService = inject(UserManagementService);
    const router = inject(Router);
    
    return userManagementService.whenLoggedIn().pipe(
        first(), // auto unsubscribe after the first value is received
        tap((user?: User): void => {
            if (user && user.role !== Role.Admin && !user.projects_ids.length) {
                router.navigate(['user-projects'], { queryParams: route.queryParams });
            } else if (!user) {
                router.navigate(['login'], { queryParams: route.queryParams });
            }
        }),
        map((user?: User) => !!user && (user.role === Role.Admin || user.projects_ids.length > 0)) // Maps the permission state to a boolean value because CanActivate should return a boolean
    );
};
