<div class="mask-popup logs-popup">
    <div class="content">
        <div class="top-wrap">
            <img alt="close" src="assets/Icons/close.png" (click)="closePopup()"/>
            <div class="title">Run Logs - {{runId}}</div>
        </div>
        <div class=" scroll-content">
        <div class="log" *ngFor="let log of logs">
            <div class="time">[{{getDateFormat(log.created_at)}} <span class="status" [class]="log.status">{{log.status}}</span>]</div>
            <div class="message">{{log.message}}</div>           
        </div>
        </div>
    </div>
</div>