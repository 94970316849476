import { Component, EventEmitter, Input, Output } from '@angular/core';
import Log from 'src/app/models/Log';
import { RunService } from 'src/app/services/run/run.service';
import { ToastrNotificationsService } from 'src/app/services/toastr-notifications/toastr-notifications.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent {
  @Output() closePopupEvent = new EventEmitter<boolean>();
  @Input() runId: number;
  logs: Log[] = [];

  constructor(private runService: RunService,
    private toastrNotificationService: ToastrNotificationsService ){}

  ngOnInit(): void {
    if(this.runId)
    this.getLogsByRunId();
  }

  getLogsByRunId(){
    this.runService.getLogsAndRunDetailsByRunId(this.runId.toString()).subscribe({
      next: (res: any) => {
        if(res && res.run && res.run.logs){
          this.logs = res.run.logs.map((log: any) => new Log(log));
        }
      },
      error: error => {
          console.error(`error get logs by run id. error: ${error}`);
          this.toastrNotificationService.errorsMessage("Get Logs", "An error occured!");
      }
  });
  }
  getDateFormat(date: Date){
    let dateArr = date.toDateString().split(' ');
    let timeArr = date.toTimeString().split(':');
    return `${dateArr[1]} ${dateArr[2]}, ${dateArr[3]} ${timeArr[0]}:${timeArr[1]}`;
  }
  
  closePopup(){
    this.closePopupEvent.emit(true);
  }

}
