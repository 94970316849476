export default class User {
    id: string = "";
    name: string = "";
    email: string = "";
    role: Role = Role.User;
    projects_ids: string[] = [];
    idToken: string;

    get organization(): string {
        const organizationPartStartIndex = this.email.indexOf('@');
        if (organizationPartStartIndex > -1) {
            const organizationPartEndIndex = this.email.indexOf('.', organizationPartStartIndex);
            if (organizationPartEndIndex > -1) {
                return this.email.substring(organizationPartStartIndex + 1, organizationPartEndIndex);
            }
        }
        return '';
    }

    constructor(data?: any) {
        if (!data) return;
        if (data.id) this.id = data.id;
        if (data.name) this.name = data.name;
        if (data.email) this.email = data.email;
        if (data.role) this.role = data.role.name || data.role;
        if (data.projects) this.projects_ids = data.projects.map((userProject: any) => userProject.project_id);
        if (data.idToken) this.idToken = data.idToken;
    }
}
export enum Role {
    Admin = "Admin",
    User = "User"
}