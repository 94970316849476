<div class="mask-popup" (click)="closePopup()">
    <div class="content" (click)="$event.stopPropagation();">
        <form [formGroup]="projectForm" (ngSubmit)="onSubmit()">
        <app-manage-project-sites [sitesList]="sitesList" [projectForm]="projectForm" ></app-manage-project-sites> 
        <div class="d-flex">
            <!-- <button (click)="updateProjectSites()" type="submit">Update</button> -->
            <button type="submit">Update</button>
            <button class="white" (click)="closePopup()">Cancel</button>
            
        </div>
    </form>
    </div>
</div>