import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Type } from 'src/app/models/Run';

@Component({
  selector: 'app-run-button-menu',
  templateUrl: './run-button-menu.component.html',
  styleUrls: ['./run-button-menu.component.scss']
})
export class RunButtonMenuComponent implements OnInit {
  @Output() RunProcess = new EventEmitter<Type>();
  constructor() { }

  get runType(): typeof Type {
    return Type;
  }

  ngOnInit(): void {
  }

  selectRun(type: Type){
   this.RunProcess.emit(type);
  }
}
