<app-header></app-header>
<div class="page project-details">
    <div class="content">
        <div class="project-details">
            <div class="table-wrap">
                <div class="d-flex">
                    <div class="title">
                        <img alt="back" src="assets/Icons/back.png" (click)="back()" />
                        <div class="name">{{project.name}}</div>
                    </div>
                </div>
                <div class="details">
                    <div class="info">{{project.info}}</div>
                    <div class="d-flex">
                        <div class="d-flex">
                            <img src="assets/Icons/person.svg" />
                            <div class="key">Contact Name:</div>
                            <div class="value">{{project.contact_name}}</div>
                        </div>
                        <div class="d-flex">
                            <img src="assets/Icons/telephone.svg" />
                            <div class="key">Contact Phone:</div>
                            <div class="value">{{project.contact_phone}}</div>
                        </div>
                        <div class="d-flex">
                            <img src="assets/Icons/envelope.svg" />
                            <div class="key">Contact Email:</div>
                            <div class="value"><a href="mailto:{{project.contact_email}}" target="_blank">{{project.contact_email}}</a></div>
                        </div>

                    </div>

                </div>
                <h2><img src="assets/Icons/expand.svg" [class.collapse]="collapseRuns" (click)="collapseRuns = !collapseRuns"/>Consolidations</h2>
                <table *ngIf="!collapseRuns">
                    <tr>
                        <th>Id</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Type</th>
                        <th class="center">Status</th>
                        <th></th>
                        <th class="center">Output</th>
                        <th class="center">Logs</th>
                    </tr>
                    <tr *ngFor="let run of project.runs | paginate: { itemsPerPage: 7, currentPage: numberRunsPagination }">
                        <td>{{run.id}}</td>
                        <td><ng-container *ngIf="run.start_date">{{getDateStr(run.start_date)}}</ng-container></td>
                        <td><ng-container *ngIf="run.end_date">{{getDateStr(run.end_date)}}</ng-container></td>
                        <td>{{run.type}}</td>
                        <td class="status center" mat-raised-button
                        [matTooltip]="run.final_log">
                            <img alt="success" src="assets/Icons/success.png" *ngIf="run.status == runStatus.success" />
                            <img alt="error" src="assets/Icons/failure.png" *ngIf="run.status == runStatus.error" />
                            <img alt="stopped" src="assets/Icons/close-tooltip.svg" *ngIf="run.status == runStatus.stopped" class="stopped"/>
                        </td>
                        <td class="center" style="padding-left: 20px">
                            <button *ngIf="run.status == runStatus.success && run.type == runType.pyTorch" (click)="predict(run.id)">Predict</button>
                            <button *ngIf="run.status == runStatus.success && run.type == runType.RAG" (click)="openRagWithChatGPT(run.id)">Open With {{chatModelLLM}}</button>
                        </td>
                        <td class="logs">
                            <img *ngIf="run.status == runStatus.success && (run.type == runType.synthetic || run.type == runType.RAG)" alt="download-icon" [matMenuTriggerData]="{'run':run}" [matMenuTriggerFor]="menuSynthetic" src="assets/Icons/download.png" />
                            <img *ngIf="run.status == runStatus.success && run.type == runType.pyTorch" alt="download-icon" [matMenuTriggerData]="{'run':run}" [matMenuTriggerFor]="menuPytorch" src="assets/Icons/download.png" />
                        </td>
                        <td class="logs" (click)="openPopupLog(run.id)"> <img src="assets/Icons/file.png" /></td>
                    </tr>
                    <tr class="empty-row" *ngIf="!project.runs || project.runs.length == 0"><td colspan="8">A consolidation process has not been run yet</td></tr>
                </table>
                <pagination-controls (pageChange)="numberRunsPagination = $event" *ngIf="!collapseRuns && project.runs.length > 7"></pagination-controls>
            </div>
            <div class="files-wrap">
                <div class="files">
                    <h2><img src="assets/Icons/expand.svg" [class.collapse]="collapseFiles" (click)="collapseFiles = !collapseFiles"/>Files</h2>
                    <div class="d-flex" *ngIf="!collapseFiles && project.sites && project.sites.length">
                        <app-run-button-menu (RunProcess)="runProcess($event)"></app-run-button-menu>
                        <img alt="menu" [matMenuTriggerFor]="menu" src="assets/Icons/menu.png" />
                    </div>
                   
                    <div class="table-wrap" *ngIf="!collapseFiles">
                        <table>
                            <tr>
                                <th>Site</th>
                                <th>Link To Retrieve</th>
                                <th>Input File</th>
                                <th></th>

                            </tr>
                            <tr *ngFor="let projectSite of project.sites">
                                <td>{{projectSite.site_name}}</td>
                                <td class="path">{{projectSite.link_to_retrieve}}</td>
                                <td class="input-file" >
                                    <ng-container *ngIf="projectSite.input_file_path">
                                        <span *ngIf="projectSite.input_file_created_at">{{getDateStr(projectSite.input_file_created_at)}}</span>
                                        <img src="assets/Icons/close_16px.png" *ngIf="projectSite.input_file_path"
                                            (click)="deleteInputFile(projectSite)" />
                                    </ng-container>
                                </td>
                                <td class="buttons">
                                    <div *ngIf="!projectSite.link_to_retrieve" class="wrap-input">
                                        <button>Upload</button>
                                        <input type="file" (change)="updateOutputFile($event, projectSite)" accept=".csv,.pth" />
                                    </div>
                                    <div *ngIf="projectSite.link_to_retrieve" class="wrap-input">
                                        <button (click)="retrieveFileProjectSite(projectSite.id)">Retrieve</button>
                                    </div>
                                </td>
                            </tr>

                        </table>
                    </div>
                </div>
            </div>
        </div>
        <app-edit-project-sites-popup [project]="project" *ngIf="showEditProjectSitesPopup"
            (closePopupEvent)="closeEditProjectSitesPopup($event)"></app-edit-project-sites-popup>
        <app-federated-learning-predict-popup *ngIf="showPredictPopup" (closePopupEvent)="showPredictPopup=false" [runId]="popupRunId"></app-federated-learning-predict-popup>
        <mat-menu #menu="matMenu" [class]="'custom-panel-edit-menu'">
            <ng-template matMenuContent let-user="user">
                <button mat-menu-item (click)="showEditProjectSitesPopup = true;"><img
                        src="assets/Icons/edit_30px.svg" /> Edit Sites</button>
                <button mat-menu-item (click)="retrieveAll()" [disabled]="retrieveFilesExist()"><img src="assets/Icons/data-request-1-30-px.svg" /> Retrieve All</button>
            </ng-template>
        </mat-menu>
        <mat-menu #menuSynthetic="matMenu" class="custom-panel-edit-menu">
            <ng-template matMenuContent let-run="run">
                <button (click)="downloadOutputFile(run)" mat-menu-item><img src="assets/Icons/filetype-csv.svg"/>Consolidated File</button>
            </ng-template>
          </mat-menu>
        <mat-menu #menuPytorch="matMenu" class="custom-panel-edit-menu">
            <ng-template matMenuContent let-run="run">
                <button (click)="downloadOutputFile(run, outputType.script)" mat-menu-item><img src="assets/Icons/file-earmark-code.svg"/>Script</button>
                <button (click)="downloadOutputFile(run, outputType.application)" mat-menu-item><img src="assets/Icons/window.svg"/>Application</button>
                <button disabled="true" mat-menu-item><img src="assets/Icons/chevron-up.svg"/>SMART on FHIR</button>
            </ng-template>
        </mat-menu>
    </div>
    <app-run-loader-popup *ngIf="showRunProcessPopup" (closePopupEvent)="closeRunPopup()" (openRagWithChatGPTEvent)="openRagWithChatGPT($event)" [runId]="popupRunId"></app-run-loader-popup>
    <app-logs *ngIf="showLogsPopup" [runId]="popupRunId" (closePopupEvent)="showLogsPopup=false"></app-logs>
</div>
<div class="custom-spinner" *ngIf="isLoading">
    <app-spinner></app-spinner>
</div>