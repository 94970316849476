export default class Log {
    id: number;
    run_id: number;
    status: Status;
    message: string;
    created_at: Date;

    constructor(data?: any) {
        if (!data) return;
        if (data.id) this.id = data.id;
        if (data.run_id) this.run_id = data.run_id;
        if (data.status) this.status = data.status;
        if (data.message) this.message = data.message;
        if (data.created_at) this.created_at = new Date(data.created_at);
    }
}

export enum Status {
    success = "success",
    error = "error",
    warning = "warning",
    stopped = "stopped",
    info= "info"
}