<app-header></app-header>
<div class="page projects user-projects">
    <div *ngIf="allProjects &&!allProjects.length">
        <div class="welcome-message">
            <b><h2>Welcome to NESTcc Connect site!</h2></b>
            <p>Thank you for registering with us. Your details have been successfully submitted.</p>
            <p>Your application is now under review by our system administrator to create the appropriate roles for your
                account. This process is part of our commitment to maintaining a secure and tailored experience for each
                user.</p>
            <p>Once your credentials have been verified and your roles are established, you will receive an email at the
                address you provided with all the necessary access details.</p>
            <p>We appreciate your patience during this process. If you have any questions or need further
                assistance, please do not hesitate to contact us at <a href="mailto:{{contactInformation.email}}" target="_blank">{{contactInformation.email}}</a> or {{contactInformation.phone}} - {{contactInformation.name}}.</p>
            <p>Welcome aboard, and we look forward to having you with us!</p>
        </div>
    </div>
    <div *ngIf="allProjects && allProjects.length">
        <div class="title">
            <h2>Projects | <span class="count">{{ projects.length + ' out of ' + allProjects.length }}</span></h2>
            <app-search [items]="allProjects" [searchKeys]="['id', 'name']"
                (itemsListChange)="onSearchChange($event)"></app-search>
        </div>
        <div class="table-wrap">
            <table>
                <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Contact Name</th>
                    <th>Contact Email</th>
                    <th>Contact Phone</th>
                    <th>Output File</th>
                    <th></th>
                </tr>
                <tr *ngFor="let project of projects | paginate: { itemsPerPage: 7, currentPage: numberUserProjectsPagination }">
                    <td class="id">{{project.id}}</td>
                    <td [title]="project.name">{{project.name}}</td>
                    <td>{{project.contact_name}}</td>
                    <td><a href="mailto:{{project.contact_email}}" target="_blank">{{project.contact_email}}</a></td>
                    <td>{{project.contact_phone}}</td>
                    <td class="output-file">
                        <span>{{project.lastSuccessRunStartDate}}</span>
                        <img *ngIf="project.lastSuccessRunStartDate" [matMenuTriggerData]="{ project: project }" [matMenuTriggerFor]="downloadMenu" src="assets/Icons/download.png" />
                    </td>
                    <td><img src="assets/Icons/eye.svg" (click)="openViewPopup(project.id)" /></td>
                </tr>
            </table>
            <pagination-controls (pageChange)="numberUserProjectsPagination = $event" *ngIf="projects.length > 7"></pagination-controls>
        </div>
    </div>
    <app-view-project-popup *ngIf="showViewProjectPopup" [projectId]="projectIdForView"
        (downloadOutputFileEvent)="downloadOutputFileListener($event)" (closePopupEvent)="showViewProjectPopup = false">
    </app-view-project-popup>
</div>
<div class="custom-spinner" *ngIf="isLoading">
    <app-spinner></app-spinner>
</div>
<mat-menu #downloadMenu class="download-menu">
    <ng-template matMenuContent let-project="project">
        <ng-container *ngIf="[runType.RAG, runType.synthetic].includes(project.lastSuccessRunType)">
            <button mat-menu-item (click)="downloadOutputFile(project)"><img src="assets/Icons/filetype-csv.svg"/>Consolidated File</button>
        </ng-container>
        <ng-container *ngIf="project.lastSuccessRunType === runType.pyTorch">
            <button (click)="downloadOutputFile(project, outputType.script)" mat-menu-item><img src="assets/Icons/file-earmark-code.svg"/>Script</button>
            <button (click)="downloadOutputFile(project, outputType.application)" mat-menu-item><img src="assets/Icons/window.svg"/>Application</button>
            <button disabled="true" mat-menu-item><img src="assets/Icons/chevron-up.svg"/>SMART on FHIR</button>
        </ng-container>
    </ng-template>
</mat-menu>