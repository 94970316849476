import { Component, EventEmitter, Input, Output } from '@angular/core';
import Project from 'src/app/models/Project';
import Roles from 'src/app/models/Roles';
import User, { Role } from 'src/app/models/User';
import { ProjectService } from 'src/app/services/project/project.service';
import { RoleService } from 'src/app/services/role/role.service';
import { ToastrNotificationsService } from 'src/app/services/toastr-notifications/toastr-notifications.service';

@Component({
    selector: 'app-edit-user-popup',
    templateUrl: './edit-user-popup.component.html',
    styleUrls: ['./edit-user-popup.component.scss']
})
export class EditUserPopupComponent {
    @Input() editedUsers: User[] = [];
    @Output() updateUsersEvent = new EventEmitter<User>();
    @Output() updateUserRolesEvent = new EventEmitter<User[]>();
    @Output() closePopupEvent = new EventEmitter<boolean>();
    projects: Project[] = [];
    formUser: User = new User();
    roles: Roles[] = [];
    updatedUserRoles: User[] = [];

    get editedUsersNames() {
        return this.editedUsers.map(user => user.name).join(', ');
    }

    constructor(private projectService: ProjectService, private toastrNotificationService: ToastrNotificationsService, private roleService: RoleService) { }

    ngOnInit() {
        if (this.editedUsers.length == 1) {
            this.formUser.projects_ids = this.editedUsers[0].projects_ids.slice();
        }
        this.updatedUserRoles = this.editedUsers;
        this.getProjects();
        this.getRoles();
    }

    getProjects() {
        this.projectService.getAll().subscribe({
            next: (res: any) => {
                if (res && res.projects) {
                    this.projects = res.projects.map((project: any) => new Project(project));
                }
            },
            error: error => {
                console.error(`error while get projects. error: ${error}`);
                this.toastrNotificationService.errorsMessage("Get Projects", "An error occurred!");
            }
        });
    }

    getRoles() {
        this.roleService.getAll().subscribe({
            next: (res: any) => {
                if (res && res.roles) {
                    this.roles = res.roles.map((role: any) => new Roles(role));
                }
            },
            error: error => {
                console.error(`error while get roles. error: ${error}`);
                this.toastrNotificationService.errorsMessage("Get Roles", "An error occured!");
            }
        });
    }

    closePopup() {
        this.closePopupEvent.emit(true);
    }

    updateUsers() {
        this.updateUsersEvent.emit(this.formUser);
        this.updateUserRoles();
    }

    projectSelectionChange(projectId: string) {
        if (this.formUser.projects_ids.includes(projectId)) {
            this.formUser.projects_ids = this.formUser.projects_ids.filter(id => id != projectId);
        } else {
            this.formUser.projects_ids.push(projectId);
        }
    }

    updateUsersForm(new_role: string, user_id: string) {
        let i = this.updatedUserRoles.findIndex(u => u.id == user_id);
        if (new_role == Role.Admin) {
            this.updatedUserRoles[i].role = Role.Admin;
        } else {
            this.updatedUserRoles[i].role = Role.User;
        }
        this.setProjectsByRole(new_role);
    }

    setProjectsByRole(role: string){
        let projectsArray = [] as string[];
        if (role == Role.Admin) {
            this.projects.forEach(project => {
                projectsArray.push(project.id);
            });
            this.formUser.projects_ids = projectsArray;
        
        } else{
            this.formUser.projects_ids = [];
            this.toastrNotificationService.infoMessage("Edit User", "Please make sure you assign a project for the user, otherwise the user will wait for the admin", true);
        }

    }

    updateUserRoles() {
        this.updateUserRolesEvent.emit(this.updatedUserRoles);
    }
}
