import Project from "./Project";

export default class Agents {
    id: string = "";
    name: string = "";
    project: Project = new Project();
    os: string;
    memory: string;
    compute: string;
    pre_requirement_software: string;
    libs: string;
    expected_run_time: string;
    explanation: string;
    installation_instruction: File;
    agent_file_windows: File;
    agent_file_linux: File;
    agent_file_mac: File;
    master_file: File;
    icon?: string;

    constructor(data?: any) {
        if (!data) return;
        if (data.id) this.id = data.id;
        if (data.name) this.name = data.name;
        if (data.project) this.project = new Project(data.project);
        if (data.os) this.os = data.os;
        if (data.memory) this.memory = data.memory;
        if (data.compute) this.compute = data.compute;
        if (data.pre_requirement_software) this.pre_requirement_software = data.pre_requirement_software;
        if (data.libs) this.libs = data.libs;
        if (data.expected_run_time) this.expected_run_time = data.expected_run_time;
        if (data.explanation) this.explanation = data.explanation;
        if (data.installation_instruction_location) this.installation_instruction = data.installation_instruction_location;
        if (data.agent_linux_location) this.agent_file_linux = data.agent_linux_location;
        if (data.agent_windows_location) this.agent_file_windows = data.agent_windows_location;
        if (data.agent_mac_location) this.agent_file_mac = data.agent_mac_location;
        if (data.master_file_location) this.master_file = data.master_file_location;
        if (data.icon && data.icon != '' && data.icon != 'null') this.icon = data.icon;
    }
}

export enum FilePurpose {
    icon = "icon",
    masterFile = "master_file",
    userGuide = "installation_instruction",
    agentWindows = "agent_file_windows",
    agentLinux = "agent_file_linux",
    agentMac = "agent_file_mac"
}