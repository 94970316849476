import { Injectable } from '@angular/core';
import User from '../../models/User';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  private user?: User;
  private logIns: Subject<User|undefined> = new ReplaySubject(1); //emits the last value for each new subscriber but only after there is already one value to emit (it doesn't emit "null" by default, it waits for the first value).
    
  getCurrentUser() {
    return this.user;
  }

  createUser(user?: User) {
    this.user = user;
    this.logIns.next(user);
  }

  public whenLoggedIn(): Observable<User|undefined> {
    return this.logIns;
  }
}
