import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CognitoAwsService } from 'src/app/services/cognito-aws/cognito-aws.service';
import { ToastrNotificationsService } from 'src/app/services/toastr-notifications/toastr-notifications.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder, 
    private cognitoAwsService: CognitoAwsService,
    private toastrNotificationsService: ToastrNotificationsService,
  ) { }
  step: number = 1;
  emailForm: FormGroup;
  codeForm: FormGroup;
  showError: boolean;
  email: string;

  ngOnInit(): void {
    this.createFormEmail();
  }

  sendEmail() {
    if (this.emailFormControl['email'].errors) {
      this.showError = true;
    } else {
      this.showError = false;
      this.email = this.emailForm.get('email')?.value;
      this.toastrNotificationsService.successMessage('Sending code', 'The code was sent to the email address');
      this.cognitoAwsService.signUpOrSignIn(this.email).then((res) => {
        if (res) { 
          this.setCodeForm();
          this.step = 2;
        }
      }).catch((error: any) => {
        console.error('signUpOrSignIn', error);
        this.toastrNotificationsService.errorsMessage("Sign In Error", error.message || "An error occurred!");  
      });
    }
  }

  setCodeForm(){
    this.codeForm = this.formBuilder.group({
      code1: [""],
      code2: [""],
      code3: [""],
      code4: [""],
      code5: [""],
      code6: [""],
    });
  }

  sendCode() {
    const code = Object.values(this.codeForm.value).join('');
    this.cognitoAwsService.confirmSignIn(code).catch((error) => {
      console.error('confirmSignUp', error);
      if(error != "Unexpected confirm sign in response"){
        if(error.message == "Incorrect username or password."){
          this.toastrNotificationsService.errorsMessage("Confirm Sign Up Error", "You've maxed out your attempts. </br> Please click the resend email button to send a new code to your email address");
        } else{
          this.toastrNotificationsService.errorsMessage("Confirm Sign Up Error", error.message || "An error occurred!");
        }
      }
      this.setCodeForm();
      this.showError = true;
    });
  }

  keyTab(index: number) {
    if (index < 6 && this.codeFormControl["code" + index.toString()].value != '') {
      let nextItem = document.getElementById("code" + (index + 1).toString());
      if (nextItem) {
        nextItem.focus();
      }
    }
    this.showError = false;
  }

  get emailFormControl() {
    return this.emailForm.controls;
  }

  get codeFormControl() {
    return this.codeForm.controls;
  }

  backToStep1(){
    this.createFormEmail();
    this.step = 1;
  }

  createFormEmail() {
    this.emailForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
    });
  }

	onPaste(event: ClipboardEvent) {
		let clipboardData = event.clipboardData;
		let pastedText = clipboardData?.getData('text') || '';
		if (pastedText.length == 6 && !isNaN(parseInt(pastedText))) {
			this.codeForm.setValue({
				code1: pastedText[0],
				code2: pastedText[1],
				code3: pastedText[2],
				code4: pastedText[3],
				code5: pastedText[4],
				code6: pastedText[5]
			})
		}
	}
}
