export default class ContactInformation {
    name: string = "";
    email: string = "";
    phone: string = "";

    constructor(data?: any) {
        if (!data) return;
        if (data.name) this.name = data.name;
        if (data.email) this.email = data.email;
        if (data.phone) this.phone = data.phone;
    }
}