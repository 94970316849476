<app-header></app-header>
<div class="page users">
    <div class="title">
        <h2>Users | <span class="count">{{users.length + ' out of ' + allUsers.length }}</span></h2>
        <app-search *ngIf="allUsers && allUsers.length" [items]="allUsers" [searchKeys]="['id', 'name']"
            (itemsListChange)="onSearchChange($event)"></app-search>
    </div>
    <div class="table-wrap" *ngIf="allUsers && allUsers.length">
        <table>
            <tr>
                <th>
                    <input type="checkbox" [checked]="selectAllChecked" (change)="selectAllChange()" *ngIf="users.length > 1"/>
                </th>
                <th>Email</th>
                <th>Role</th>
                <th>Organization</th>
                <th></th>
                <th class="menu">
                    <img *ngIf="selectedUsersIds.length" src="assets/Icons/three-dots-vertical.svg"
                        (click)="openContextMenu($event)" />
                </th>
            </tr>
            <tr *ngFor="let user of users | paginate: { itemsPerPage: 7, currentPage: numberUsersPagination }">
                <td class="checkbox">
                    <input type="checkbox" [checked]="selectedUsersIds.includes(user.id)"
                        (change)="userSelectionChange(user.id)" *ngIf="user.id != getCurrentUserId()"/>
                </td>
                <td class="name">{{user.email}} <span class="current-user" *ngIf="user.id == getCurrentUserId()">You</span></td>
                <td class="role"><img src="assets/Icons/{{iconByRole(user.role)}}.svg" />{{user.role}}</td>
                <td>{{user.organization}}</td>
                <td class="edit">
                    <img src="assets/Icons/edit_30px.svg" (click)="editUser(user)" *ngIf="user.id != getCurrentUserId()"/>
                </td>
                <td class="delete" >
                    <img src="assets/Icons/delete.png" (click)="deleteUser(user)" *ngIf="user.id != getCurrentUserId()"/>
                </td>
            </tr>
        </table>
        <pagination-controls (pageChange)="numberUsersPagination = $event"
            *ngIf="users.length > 7"></pagination-controls>
    </div>
    <div class="empty-wrap" *ngIf="!allUsers || !allUsers.length">
        <h2>You don’t have data users.</h2>
        <h3>The users be listed here.</h3>
        <img alt="empty projects list" src="assets/Icons/project-list-empty.png" />
    </div>
    <app-edit-user-popup *ngIf="showEditPopup" [editedUsers]="editedUsers || []" (closePopupEvent)="showEditPopup=false"
        (updateUsersEvent)="updateUsers($event)" (updateUserRolesEvent)="updateUserRoles($event)">
    </app-edit-user-popup>
    <app-delete-popup *ngIf="showDeletePopup" [title]="titleForDeletePopup" [content]="contentMessageForDeletePopup" (closePopupEvent)="closeDeleteUserPopup($event)">
    </app-delete-popup>
</div>

<div style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
    [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu" [class]="'custom-panel-edit-user-menu'">
    <ng-template matMenuContent let-user="user">
        <button mat-menu-item (click)="deleteSelectedUsers()"><img class="deleteIco"
                src="assets/Icons/delete.png" /> Delete selected users</button>
    </ng-template>
</mat-menu>
<div class="custom-spinner" *ngIf="isLoading">
    <app-spinner></app-spinner>
</div>