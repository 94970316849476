import { Component, Input } from '@angular/core';
import ProjectSite from 'src/app/models/ProjectSite';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import Site from 'src/app/models/Site';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-manage-project-sites',
  templateUrl: './manage-project-sites.component.html',
  styleUrls: ['./manage-project-sites.component.scss']
})

export class ManageProjectSitesComponent {
  @Input() sitesList: Site[];
  @Input() projectForm :FormGroup;
  email = environment.googleServiceAccountEmail;

  constructor(private formBuilder: FormBuilder){

  }



  siteIsDisabled(siteItem: Site, index: number){
    let currentSite = this.siteFormGroup.find(site => { return site.value.site_id == siteItem.id });
    if (currentSite && this.getFormSiteByIndex(index)['site_id'].value != siteItem.id){
      return true;
    }
    return false;
  }

  removeProjectSite(index: number) {
    if (this.siteFormGroup.length > 1) {
      const control = <FormArray>this.projectForm.controls['sites'];
      control.removeAt(index);
    }
  }

  addProjectSite() {
    let lastProjectSite = this.siteFormGroup[this.siteFormGroup.length - 1].value;
    if (lastProjectSite.site_id != "") {
      const siteForm = this.formBuilder.group({
        id: [""],
        site_id: ["", Validators.required],
        link_to_retrieve: ["", [Validators.pattern("^(sftp://|https://drive.google.com/file/d/).*")]]
      });
      this.siteFormGroup.push(siteForm);
      this.projectForm.get('sites')?.updateValueAndValidity();
      siteForm.valueChanges.subscribe(value => {
        this.projectForm.get('sites')?.updateValueAndValidity();
      });
    } 
  }
  
  get siteFormGroup() {
    return (this.projectForm.controls["sites"]as FormArray)["controls"] ;
  }
  get firstFormSite() {
    return ((this.projectForm.controls["sites"]as FormArray)["controls"][0] as FormGroup<any>)["controls"]
  }
  getFormSiteByIndex(index: number){
    return ((this.projectForm.controls["sites"]as FormArray)["controls"][index] as FormGroup<any>)["controls"]
  }

}
