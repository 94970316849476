<app-header></app-header>
<div class="page agent">
    <div>
        <div class="title-section">
            <div class="title">
                <img class="back" alt="back" src="assets/Icons/back.png" [routerLink]="['/', 'agents']"/>
                <img class="agent-icon" *ngIf="!agent.icon" src="./assets/Icons/agent-default.webp"/>
                <img class="agent-icon" *ngIf="agent.icon" [src]="iconHost+'/'+agent.icon | secure | async"/>
                <div class="agent-name">{{agent.name}}</div>
            </div>
        </div>
        <div class="section">
            <div class="title">Downloads</div>
            <div class="buttons">
                <a class="download-button button" [class.disabled]="!agent.agent_file_windows" (click)="downloadAgent(filePurpose.agentWindows)">
                    <img src="assets/Icons/windows.png" />Windows
                </a>
                <a class="download-button button" [class.disabled]="!agent.agent_file_linux" (click)="downloadAgent(filePurpose.agentLinux)">
                    <img src="assets/Icons/linux.png" />Linux
                </a>
                <a class="download-button button" [class.disabled]="!agent.agent_file_mac" (click)="downloadAgent(filePurpose.agentMac)">
                    <img src="assets/Icons/apple-logo.png" />MacOs
                </a>
            </div>
        </div>
        <div class="section">
            <div class="title">Overview</div>
            <div class="text">{{agent.explanation}}</div>
        </div>
        <div class="section">
            <div class="title">Details</div>
            <ul class="details">
                <li>
                    <div class="key">OS</div>
                    <div class="value">{{agent.os}}</div>
                </li>
                <li>
                    <div class="key">Memory</div>
                    <div class="value">{{agent.memory}}</div>
                </li>
                <li>
                    <div class="key">Compute</div>
                    <div class="value">{{agent.compute}}</div>
                </li>
                <li>
                    <div class="key">Expected run time</div>
                    <div class="value">{{agent.expected_run_time}}</div>
                </li>
                <li>
                    <div class="key">Pre requirement software</div>
                    <div class="value">{{agent.pre_requirement_software}}</div>
                </li>
                <li>
                    <div class="key">Libs</div>
                    <div class="value">{{agent.libs}}</div>
                </li>
            </ul>
        </div>
        <div class="section only-download">
            <div class="title">User Guide</div>
            <div class="button" (click)="downloadUserGuide()">Download</div>
        </div>
        <div class="section only-download" *ngIf="agent.master_file">
            <div class="title">Master File</div>
            <div class="button" (click)="downloadMasterFile()">Download</div>
        </div>
    </div>
    <div class="management-section" *ngIf="userRole === role.Admin">
        <a class="button" (click)="showEditPopup = true"><img src="assets/Icons/edit_30px.svg" />Edit</a>
        <a class="button" (click)="showDeletePopup = true"><img src="assets/Icons/delete.png" />Delete</a>
    </div>
    <app-delete-popup *ngIf="showDeletePopup" 
        (closePopupEvent)="closeDeletePopup($event)" 
        title="Delete Agent"
        content="Are you sure you want to delete the {{agent.name}} agent?">
    </app-delete-popup>
    <app-new-agent-popup *ngIf="showEditPopup" 
        (closePopupEvent)="closeEditPopup($event)"
        [agent]="agent">
    </app-new-agent-popup>
</div>
<div class="custom-spinner" *ngIf="isLoading">
    <app-spinner></app-spinner>
</div>