import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import Log from 'src/app/models/Log';
import Run, { Status, Type } from 'src/app/models/Run';
import { RunService } from 'src/app/services/run/run.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-run-loader-popup',
  templateUrl: './run-loader-popup.component.html',
  styleUrls: ['./run-loader-popup.component.scss']
})
export class RunLoaderPopupComponent implements OnChanges {
  @Output() closePopupEvent = new EventEmitter<boolean>();
  @Output() openRagWithChatGPTEvent = new EventEmitter<number>();
  @Input() runId: number;
  logs: Log[] = [];
  logInterval: NodeJS.Timeout;
  runDetails: Run;

  get runStatus(): typeof Status {
    return Status;
  }

  get runType(): typeof Type {
    return Type;
  }

  get chatModelLLM(): string {
    return environment.chatModelLLM;
  }

  constructor(private runService: RunService) {
    this.getLogsByRunId = this.getLogsByRunId.bind(this);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['runId']) {
        if (this.runId) {
            this.updateLogsProcess();
        }
    }
  }

  openChatGPT() {
    this.openRagWithChatGPTEvent.emit(this.runId);
  }
  
  updateLogsProcess() {
    this.logInterval = setInterval(() => {
        this.getLogsByRunId(); 
    }, 1000);
  }

  getDateFormat(date: Date) {
    let dateArr = date.toDateString().split(' ');
    let timeArr = date.toTimeString().split(':');
    return `${dateArr[1]} ${dateArr[2]}, ${dateArr[3]} ${timeArr[0]}:${timeArr[1]}`;
  }


  getLogsByRunId() {
    this.runService.getLogsAndRunDetailsByRunId(this.runId.toString()).subscribe({
      next: (res: any) => {
        if(res && res.run){
          this.runDetails = new Run(res.run);
          this.logs = res.run.logs.map((log: any) => new Log(log));
          // scroll to bottom
          setTimeout(() => {
            let objDiv = document.getElementById("logs-id");
            if(objDiv){
             objDiv.scrollTop = objDiv.scrollHeight;
            }
          },0)   
          if(res.run.status == Status.error || res.run.status == Status.success || res.run.status === Status.stopped){
            clearInterval(this.logInterval);
          }
        }
       
      },
      error: error => {
        console.error(`error while get logs by run id. error: ${error}`);
      }
    });
  }

  stopProcess(){
    this.runService.stopRun(this.runId).subscribe();
  }

  closePopup() {
    this.closePopupEvent.emit(true);
  }


}
