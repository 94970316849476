import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastrNotificationsService {

  constructor(private toastr: ToastrService) { }

  successMessage(title: string, message: string) {
    this.toastr.success(message, title, {
      closeButton: true,
      toastClass: 'mdclone-toast ngx-toastr success',
      titleClass: 'toastr-title',
      messageClass: 'toastr-message',
      enableHtml: true

    });
  }

  errorsMessage(title: string, message: string, disableTimeOut?:boolean) {
    this.toastr.error(message, title, {
      disableTimeOut:disableTimeOut? disableTimeOut: false,
      closeButton: true,
      toastClass: 'mdclone-toast ngx-toastr error',
      titleClass: 'toastr-title',
      messageClass: 'toastr-message',
      enableHtml: true
      

    });
  }

  infoMessage(title: string, message: string, disableTimeOut?:boolean) {
    this.toastr.info(message, title, {
      disableTimeOut:disableTimeOut? disableTimeOut: false,
      closeButton: true,
      toastClass: 'mdclone-toast ngx-toastr info',
      titleClass: 'toastr-title',
      messageClass: 'toastr-message',
      enableHtml: true

    });
  }
}
