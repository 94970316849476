<div class="mask-popup predict-popup" (click)="closePopup()">
    <div class="content" (click)="$event.stopPropagation();">
        <div class="wrap-title">
            <h1>Predict</h1>
            <img alt="close" src="assets/Icons/close.png" (click)="closePopup()" />
        </div>
        <div class="wrap-field">
            <div class="label">Select a file for prediction</div>
            <div class="flex-wrap">
                <input type="text" [value]="fileToPredict?.name" readonly />
                <div class="wrap-input" id="uploadMasterFileDiv">
                    <input type="file" (change)="updatePredictFile($event)" accept=".csv" />
                    <div class="upload-input-btn">Upload File<img alt="upload" src="assets/Icons/uploadPurple.png" />
                    </div>
                </div>
            </div>
        </div>
        <div class="wrap-button">
        <button [disabled]="!fileToPredict" (click)="runPredict()">Predict</button>
    </div>
    <div class="meter">
        <span [style.width.%]="loadingNumberPercentStatus"><span class="progress"></span></span>
    </div>
        <div class="wrap-input buttons">
            <button class="upload-input-btn" [disabled]="downloadFilePath == ''" (click)="downloadFile()">Download File<img alt="upload" src="assets/Icons/downloadPurple.png" /></button>
        </div>
        <div class="error" *ngIf="shoeErrorRunPredict">Error: {{errorMessage}}</div>
    </div>
</div>