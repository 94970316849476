import Log from "./Log";
import OutputFile from "./OutputFile";

export default class Run {
    id: number;
    start_date: Date;
    end_date: Date;
    output_file: OutputFile;
    final_log: string;
    status: Status;
    type: Type;
    logs: Log[];

    constructor(data?: any) {
        if (!data) return;
        if (data.id) this.id = data.id;
        if (data.start_date) this.start_date = new Date(data.start_date);
        if (data.end_date) this.end_date = new Date(data.end_date);
        if (data.output_file) this.output_file = new OutputFile(data.output_file);
        if (data.final_log) this.final_log = data.final_log;
        if (data.status) this.status = data.status;
        if (data.type) this.type = data.type[0].toUpperCase() + data.type.substring(1);
        if (data.logs) this.logs = data.logs.map((run: any) => new Log(run));
    }
}
export enum Status {
    stopped = "stopped",
    success = "success",
    error = "error"
}

export enum Type {
    synthetic = "Synthetic",
    pyTorch = "PyTorch",
    tensorflow = "Tensorflow",
    RAG = "RAG",
    keras = "Keras",
}