import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProjectsComponent } from './pages/projects/projects.component';
import { ProjectDetailsComponent } from './pages/project-details/project-details.component';
import { LoginComponent } from './pages/login/login.component';
import { AgentsComponent } from './pages/agents/agents.component';
import { UsersManagementComponent } from './pages/users-management/users-management.component';
import { SitesManagementComponent } from './pages/sites-management/sites-management.component';
import { UserProjectsComponent } from './pages/user-projects/user-projects.component';
import { adminGuard } from './guards/admin.guard';
import { userGuard } from './guards/user.guard';
import { hasProjectGuard } from './guards/has-project.guard';
import { noUserGuard } from './guards/no-user.guard';
import { AgentDetailsComponent } from './pages/agent-details/agent-details.component';
import { LambdaSimulatorComponent } from './pages/lambda-simulator/lambda-simulator.component';

const routes: Routes = [ {
  path: '',
  redirectTo: 'login',
  pathMatch: 'full'
},
{
  path: 'login',
  canActivate: [noUserGuard],
  component: LoginComponent
},
{
  path: 'projects',
  canActivate: [adminGuard],
  component: ProjectsComponent
},
{
  path: 'agents',
  canActivate: [hasProjectGuard],
  component: AgentsComponent
},
{
  path: 'users',
  canActivate: [adminGuard],
  component: UsersManagementComponent
},
{
  path: 'sites',
  canActivate: [adminGuard],
  component: SitesManagementComponent
},
{
  path: 'project/:id',
  canActivate: [adminGuard],
  component: ProjectDetailsComponent
},
{
  path: 'user-projects',
  canActivate: [userGuard],
  component: UserProjectsComponent
},
{
  path: 'agent/:id',
  canActivate: [userGuard],
  component: AgentDetailsComponent
},
{
  path: 'lambda-simulator',
  canActivate: [userGuard],
  component: LambdaSimulatorComponent
},
{
  path: '**',
  redirectTo: 'login',
  pathMatch: 'full'
}]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
