import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { firstValueFrom } from 'rxjs';
import User from 'src/app/models/User';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    route = 'user';

    constructor(private apiService: ApiService) { }

    async getUsers() {
        const response: any = await firstValueFrom(this.apiService.getRequest(`${this.route}`));
        return response.users.map((user: any) => new User(user));
    }

    async findOrCreateUser(name: string, email: string, idToken: string) {
        const response: any = await firstValueFrom(
            this.apiService.postRequestWithRes(`${this.route}/findOrCreate`, { user: { name, email } }, { headers: { 'id-token': idToken } })
        );
        return new User(response.user);
    }

    async updateUsers(users_ids: string[], projects_ids: string[]) {
        return await firstValueFrom(this.apiService.putRequest(`${this.route}/multi`, { users_ids, projects_ids }));
    }

    async updateUserRoles(users: User[]) {
        return await firstValueFrom(this.apiService.putRequest(`${this.route}/user-role`, { users: users }));
    }

    async deleteUsers(users_ids: string[]) {
        return await firstValueFrom(this.apiService.deleteRequestWithBody(`${this.route}/delete-multi`, { users_ids: users_ids }));
    }
}
