import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../api/api.service';
import MasterFile from 'src/app/models/MasterFile';

@Injectable({
  providedIn: 'root'
})
export class MasterFileService {
    route = 'master-file';

    constructor(private apiService: ApiService) { }
  
    async getById(id: number) {
        const response: any = await firstValueFrom(this.apiService.getRequest(`${this.route}/${id}`));
        return new MasterFile(response);
    }
}
