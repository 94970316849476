<app-header></app-header>
<div class="login page">
    <div class="content">
        <form [formGroup]="emailForm" (ngSubmit)="sendEmail()" *ngIf="step==1">
            <img src="assets/Icons/logoMDC.png">
            <input  type="email" placeholder="Enter email" formControlName="email" [class.error] = "showError" (keyup)="showError=false" autofocus/>
            <div class="error" *ngIf="emailFormControl['email'].errors?.['pattern'] && showError">Email format is not valid</div>
            <div class="error" *ngIf="emailFormControl['email'].errors?.['required'] && showError"> Email is required</div>
            <button class="email-button" type="submit"> Continue </button>
        </form>
        <form [formGroup]="codeForm" (ngSubmit)="sendCode()" *ngIf="step==2" class="code-form">
            <img src="assets/Icons/logoMDC.png">
            <div class="text">Check your email for a code</div>
            <div class="text small">Enter the verification code sent to <span>{{emailFormControl['email'].value}}</span></div>
            <div class="wrap-code" [class.error] = "showError">
                <input type="text" maxlength="1" id="code1" (keyup)="keyTab(1)" formControlName="code1" (paste)="onPaste($event)" autofocus/>
                <input type="text" maxlength="1" id="code2" (keyup)="keyTab(2)" formControlName="code2" />
                <input type="text" maxlength="1" id="code3" (keyup)="keyTab(3)" formControlName="code3" />
                <input type="text" maxlength="1" id="code4" (keyup)="keyTab(4)" formControlName="code4" />
                <input type="text" maxlength="1" id="code5" (keyup)="keyTab(5)" formControlName="code5" />
                <input type="text" maxlength="1" id="code6" (keyup)="keyTab(6)" formControlName="code6" />
            </div>
            <div class="error" *ngIf="showError">That code isn't valid. Please try again.</div>
            <button type="submit"> Send </button>

            <div class="bottom-wrap">
                Can't find our email? Check your spam or  
                <span (click)="sendEmail()">resend email</span>
                <div>Not you? <span (click)="backToStep1()">Use another account</span></div>
            </div>
        </form>
    </div>
</div>