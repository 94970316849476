<div class="mask-popup" (click)="closePopup(false)">
    <div class="content" (click)="$event.stopPropagation();">
        <div>
            <h1>{{title}}</h1>
        </div>
        <div>
            {{content}}
        </div>
        <div class="d-flex">
            <button (click)="closePopup(true)">Yes</button>
            <button class="white" (click)="closePopup(false)">No</button>

        </div>
    </div>
</div>