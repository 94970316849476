import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { ProjectDetailsComponent } from './pages/project-details/project-details.component';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { NewProjectComponent } from './components/new-project/new-project.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuComponent } from './components/menu/menu.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { LoginComponent } from './pages/login/login.component';
import { AgentsComponent } from './pages/agents/agents.component';
import { RunButtonMenuComponent } from './components/run-button-menu/run-button-menu.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MatSelectModule } from '@angular/material/select';
import { UsersManagementComponent } from './pages/users-management/users-management.component';
import { EditUserPopupComponent } from './components/edit-user-popup/edit-user-popup.component';
import { SearchComponent } from './components/search/search.component';
import { SitesManagementComponent } from './pages/sites-management/sites-management.component';
import { SitePopupComponent } from './components/site-popup/site-popup.component';
import { UserProjectsComponent } from './pages/user-projects/user-projects.component';
import { ViewProjectPopupComponent } from './components/view-project-popup/view-project-popup.component';
import { ToastrModule, provideToastr } from 'ngx-toastr';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ManageProjectSitesComponent } from './components/manage-project-sites/manage-project-sites.component';
import { EditProjectSitesPopupComponent } from './components/edit-project-sites-popup/edit-project-sites-popup.component';
import { LogsComponent } from './components/logs/logs.component';
import { DeletePopupComponent } from './components/delete-popup/delete-popup.component';
import { RunLoaderPopupComponent } from './components/run-loader-popup/run-loader-popup.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgxPaginationModule } from 'ngx-pagination';
import { ApiService } from './services/api/api.service';
import { FooterComponent } from './components/footer/footer.component';
import { FederatedLearningPredictPopupComponent } from './components/federated-learning-predict-popup/federated-learning-predict-popup.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NewAgentPopupComponent } from './components/new-agent-popup/new-agent-popup.component';
import { AgentDetailsComponent } from './pages/agent-details/agent-details.component';
import { SecurePipe } from './services/secure.pipe';
import { LambdaSimulatorComponent } from './pages/lambda-simulator/lambda-simulator.component';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LogViewerComponent } from './components/log-viewer/log-viewer/log-viewer.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ProjectsComponent,
    ProjectDetailsComponent,
    NewProjectComponent,
    MenuComponent,
    LoginComponent,
    AgentsComponent,
    RunButtonMenuComponent,
    LoaderComponent,
    UsersManagementComponent,
    EditUserPopupComponent,
    SearchComponent,
    SitesManagementComponent,
    SitePopupComponent,
    UserProjectsComponent,
    ViewProjectPopupComponent,
    SpinnerComponent,
    ManageProjectSitesComponent,
    EditProjectSitesPopupComponent,
    LogsComponent,
    DeletePopupComponent,
    RunLoaderPopupComponent,
    FooterComponent,
    FederatedLearningPredictPopupComponent,
    NewAgentPopupComponent,
    AgentDetailsComponent,
    SecurePipe,
    LambdaSimulatorComponent,
    LogViewerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    MatMenuModule,
    MatIconModule,
    HttpClientModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    ToastrModule.forRoot(),
    NgxPaginationModule,
    MatTooltipModule,
    MatListModule,
    MatInputModule,
    MatFormFieldModule
  ],
  providers: [
    provideAnimations(),
    provideToastr(),
    {
      provide: HTTP_INTERCEPTORS, useClass: ApiService, multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
