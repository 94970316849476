import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Agents, { FilePurpose } from 'src/app/models/Agents';
import { Role } from 'src/app/models/User';
import { AgentsService } from 'src/app/services/agents/agents.service';
import { FileService } from 'src/app/services/file/file.service';
import { ToastrNotificationsService } from 'src/app/services/toastr-notifications/toastr-notifications.service';
import { UserManagementService } from 'src/app/services/userManagementService/user-management.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-agent-details',
  templateUrl: './agent-details.component.html',
  styleUrls: ['./agent-details.component.scss']
})
export class AgentDetailsComponent {
    agent: Agents = new Agents();
    isLoading: boolean = false;
    showEditPopup: boolean = false;
    showDeletePopup: boolean = false;
    userRole: Role = Role.User;
    iconHost = environment.staticRequestsHost;

    get role(): typeof Role {
      return Role;
    }

    get filePurpose(): typeof FilePurpose {
      return FilePurpose;
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private agentsService: AgentsService,
        private toastrNotificationsService: ToastrNotificationsService,
        private userManagementService: UserManagementService,
        private fileService: FileService,
    ) {
        this.route.params.subscribe(params => {
            this.agent.id = params['id'];
        });
    }

    ngOnInit() {
        this.getAgentById();
        this.userRole = this.userManagementService.getCurrentUser()?.role || Role.User;
    }

    getAgentById() {
        this.isLoading = true;
        this.agentsService.getById(this.agent.id).subscribe({
            next: (res: any) => {
                this.agent = new Agents(res.agent);
                this.isLoading = false;
            },
            error: error => {
                this.isLoading = false;
                console.error('Error get agent by id', error);
                this.toastrNotificationsService.errorsMessage("Get Agent", "An error occurred!");
            }
        })
    }

    downloadAgent(type: FilePurpose) {
        this.agentsService.getAgentFile(this.agent.id, type).then(res => {
            const fileName = res.split('/').pop();
            this.fileService.downloadFile(res, fileName);
            this.toastrNotificationsService.infoMessage("Agent download", "The agent is downloading, please wait");
        }).catch(error => {
            console.error(`Error while get agent file. error: ${error}`);
            this.toastrNotificationsService.errorsMessage("Agent download", "An error occurred!");
        });
    }
    
    downloadMasterFile() {
        this.agentsService.getMasterFile(this.agent.id).then((res: any) => {
            const content = res.masterFile.master_file_content.data;
            const name = res.masterFile.master_file_name;

            this.download(content, name, "");
        }).catch(error => {
            console.error(`Error while download user guide. error: ${error}`);
            this.toastrNotificationsService.errorsMessage("Download User Guide", "An error occurred!");
        });
    }

    downloadUserGuide() {
        this.agentsService.getTutorialDocument(this.agent.id).then((res: any) => {
            const content = res.installationsInstruction.installations_instruction_content.data;
            const name = res.installationsInstruction.installations_instruction_name;

            this.download(content, name, "application/pdf");
        }).catch(error => {
            console.error(`Error while download user guide. error: ${error}`);
            this.toastrNotificationsService.errorsMessage("Download User Guide", "An error occurred!");
        });
    }

    download(content: any, name: string, type: string) {
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob([new Uint8Array(content).buffer], { type }));
        downloadLink.setAttribute('download', name);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    closeDeletePopup(toDelete: boolean) {
        if (toDelete) {
            this.deleteAgent();
        }
        this.showDeletePopup = false;
    }
    
    deleteAgent() {
        this.agentsService.delete(this.agent.id).subscribe({
            next: (res: any) => {
                this.router.navigate([`/agents`]);
                this.toastrNotificationsService.successMessage("Delete Agent", "Deleted agent successfully!");
            },
            error: (error: any) => {
                console.error('Error delete agent', error);
                this.toastrNotificationsService.errorsMessage("Delete Agent", "An error occurred!");
            }
        });      
    }
    
    closeEditPopup(event: { updated: boolean, payload?: any }) {
        if (event.updated) {
            if (event.payload.project_id > 0) {
                this.router.navigate([`/agents`]);
                return;
            }
            this.getAgentById();
        }
        this.showEditPopup = false;
    }
}
