import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import Site from 'src/app/models/Site';

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  private path = "site";
  
  constructor(private apiService: ApiService) { }

  getAll() {
    return this.apiService.getRequest(this.path);
  }

  create(site: Site) {
    return this.apiService.postRequest(this.path, { site: site });
  }

  update(site: Site) {
    return this.apiService.putRequest(this.path, { site: site });
  }

  delete(siteId: string) {
    return this.apiService.deleteRequest(this.path + `/${siteId}`);
  }

  getSiteById(id: string) {
    return this.apiService.getRequest(this.path + "/" + id)
  }

  isSiteExists(site_name: string) {
    return this.apiService.getRequest(this.path + `/is-exists/${site_name}`)
  }
}
