import MasterFile from "./MasterFile";
import Run, { Status, Type } from "./Run";
import ProjectSite from "./ProjectSite";

export default class Project {
    id: string = "";
    name: string = "";
    info: string = "";
    contact_name: string = "";
    contact_phone: string = "";
    contact_email: string = "";
    master_file_id: number = 0;
    filesProcessInput: File[] = [];
    sites: ProjectSite[] = [];
    master_file: MasterFile;
    runs: Run[] = [];

    get lastSuccessRunStartDate(): string {
        const date = this.runs.find(run=> run.status === Status.success)?.start_date;
        if (date) {
            return this.getDateStr(date);
        }
        return '';
    }
    get lastSuccessRunEndDate(): string {
        const date = this.runs.find(run=> run.status === Status.success)?.end_date;
        if (date) {
            return this.getDateStr(date);
        }
        return '';
    }
    get lastSuccessRunId(): number {
        return this.runs.find(run=> run.status == Status.success)?.id || 0;
        
    }
    get lastSuccessRunType(): Type|null {
        return this.runs.find(run=> run.status === Status.success)?.type || null;
    }

    constructor(data?: any) {
        if (!data) return;
        if (data.id) this.id = data.id;
        if (data.name) this.name = data.name;
        if (data.info) this.info = data.info;
        if (data.contact_name) this.contact_name = data.contact_name;
        if (data.contact_phone) this.contact_phone = data.contact_phone;
        if (data.contact_email) this.contact_email = data.contact_email;
        if (data.master_file_id) this.master_file_id = data.master_file_id;
        if (data.filesProcessInput) this.filesProcessInput = data.filesProcessInput;
        if (data.sites) this.sites = data.sites.map((s: any) => new ProjectSite(s));
        if (data.master_file) this.master_file = new MasterFile(data.master_file);
        if (data.runs) this.runs = data.runs.map((run: any) => new Run(run));
    }

    getDateStr(date: Date) {
        let dateArr = date.toDateString().split(' ');
        let timeArr = date.toTimeString().split(':');
        return `${dateArr[1]} ${dateArr[2]}, ${dateArr[3]} ${timeArr[0]}:${timeArr[1]}`;
    }
}
export enum PopupType {
    create = "create",
    edit = "edit"
}