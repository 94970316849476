<div class="mask-popup" (click)="closePopup()">
    <div class="content"  (click)="$event.stopPropagation();">
        <div class="wrap-title">
            <h1>Edit {{ editedUsers.length > 1 ? 'Users' : 'User' }}</h1>
            <img alt="close" src="assets/Icons/close.png" (click)="closePopup()" />
        </div>
        <div class="wrap-field-title">
            <div class="field-title">Role</div>
        </div>
        <div class="wrap-field users" *ngFor="let user of editedUsers">
            <div class="label">{{user.email}}</div>
            <mat-form-field>
                <mat-select [value]="user.role" (valueChange)="updateUsersForm($event, user.id)">
                    <mat-option *ngFor="let role of roles" [value]="role.name">{{role.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="wrap-field-title">
            <div class="field-title">Projects</div>
            <div class="button-link" (click)="formUser.projects_ids = []">Clear all</div>
        </div>
        <div *ngFor="let project of projects" class="project" class="wrap-field">
            <input type="checkbox" [checked]="formUser.projects_ids.includes(project.id)" (change)="projectSelectionChange(project.id)"/>
            <div class="label">{{project.name}}</div>
        </div>
        <div class="d-flex">
            <button class="white" (click)="closePopup()">Cancel</button>
            <button (click)="updateUsers()">Save</button>
        </div>
    </div>
</div>