import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Project from 'src/app/models/Project';
import ProjectSite from 'src/app/models/ProjectSite';
import Site from 'src/app/models/Site';
import { ProjectService } from 'src/app/services/project/project.service';
import { SiteService } from 'src/app/services/site/site.service';
import { ToastrNotificationsService } from 'src/app/services/toastr-notifications/toastr-notifications.service';

@Component({
  selector: 'app-edit-project-sites-popup',
  templateUrl: './edit-project-sites-popup.component.html',
  styleUrls: ['./edit-project-sites-popup.component.scss']
})
export class EditProjectSitesPopupComponent {
  @Input() project: Project;
  sitesList: Site[] = [];
  projectForm: FormGroup;
  @Output() closePopupEvent = new EventEmitter<boolean>();

  constructor(private siteService: SiteService, 
    private toastrNotificationService: ToastrNotificationsService,
     private formBuilder: FormBuilder,
     private projectService: ProjectService) {
  }

  ngOnInit() {
    this.getSites();
    this.createForm();
  }

  getSites() {
    this.siteService.getAll().subscribe({
      next: (res: any) => {
        this.sitesList = [];
        res.sites.forEach((site: any) => {
          this.sitesList.push(new Site(site));
        });
      },
      error: error => {
        console.error('Error get all sites', error);
        this.toastrNotificationService.errorsMessage("Get Sites", "An error occurred!");
      }
    });
  }

  createForm() {
    this.projectForm = this.formBuilder.group({
      id: [this.project.id],
      name: [this.project.name, Validators.required],
      info: [this.project.info, Validators.required],
      contact_name: [this.project.contact_name, Validators.required],
      contact_phone: [this.project.contact_phone, [Validators.required, Validators.pattern("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$")]],
      contact_email: [this.project.contact_email, [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
      sites: this.formBuilder.array(this.buildProjectSitesGroup(this.project.sites))

    });
  }

  buildProjectSitesGroup(projectSites: ProjectSite[]) {
    return projectSites.map(projectSite => {
      return this.formBuilder.group({
        id: [projectSite.id],
        site_id: [projectSite.site_id, Validators.required],
        link_to_retrieve: [projectSite.link_to_retrieve, Validators.pattern("^(sftp://|https://drive.google.com/file/d/).*")]
      })
    })
  }

  onSubmit() {
    if(this.projectForm.valid){
    this.project = {
      ...this.projectForm.getRawValue()
    };
    this.projectService.update(this.project).subscribe({
      next: (res: any) => {
        console.log(res);
        this.closePopupEvent.emit(true);
        this.toastrNotificationService.successMessage("Update Project", "Updated project successfully!");
      },
      error: error => {
        console.error('Error update project', error);
        this.toastrNotificationService.errorsMessage("Update Project", "An error occurred!");
      }
    });
  }
  }

  closePopup() {
    this.closePopupEvent.emit(false);
  }

}
