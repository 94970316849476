import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { firstValueFrom } from 'rxjs';
import Roles from 'src/app/models/Roles';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  route = 'role';
  constructor(private apiService: ApiService) { }

  getAll() {
    return this.apiService.getRequest(`${this.route}`);
  }
}
