import { Component, OnInit } from '@angular/core';
import { LambdaSimulatorService } from 'src/app/services/lambda-simulator/lambda-simulator.service';
import { ToastrNotificationsService } from 'src/app/services/toastr-notifications/toastr-notifications.service';

@Component({
  selector: 'app-lambda-simulator',
  templateUrl: './lambda-simulator.component.html',
  styleUrls: ['./lambda-simulator.component.scss']
})
export class LambdaSimulatorComponent implements OnInit {
  number_of_instances = 0;
  aws_code_lambda: string[] = [];
  clicks_lst: string[] = ["1"];
  isNumberNotValid = true;
  duplicatesExist = false;
  selectionDict: any = {};
  isRunBtnShouldDisabled: boolean = false;
  isClickedOnTheRunBtn = false;
  functionNames: any = [];
  initialLogs: string[] = [];

  constructor(private lambdaSimulatorService: LambdaSimulatorService, private toastrNotificationService: ToastrNotificationsService) { }

  ngOnInit(): void {
    this.number_of_instances = 12;
    this.lambdaSimulatorService.getFunctionNames().subscribe({
      next: (res: any) => {
        this.functionNames = res.functionNames;
        this.aws_code_lambda = this.functionNames.filter((f: any) => !f.IsDisabled).map((f1: any) => f1.Name);
        this.selectionDict["1"] = this.functionNames.filter((f: any) => !f.IsDisabled)[0]["Name"];
      },
      error: error => {
        console.error('Error get function names', error);
        this.isRunBtnShouldDisabled = false;
        this.toastrNotificationService.errorsMessage("Get Function Names", "An error occurred!");
      }
    })
  }

  addRow() {
    let new_id = (this.clicks_lst.length + 1).toString()
    this.clicks_lst.push(new_id);
    this.selectionDict[new_id] = this.functionNames.filter((f: any) => !f.IsDisabled)[0]["Name"];
  }

  minusRow() {
    delete this.selectionDict[this.clicks_lst.length.toString()];
    this.clicks_lst.pop();
  }

  runLambda() {
    this.isClickedOnTheRunBtn = true;
    this.isRunBtnShouldDisabled = true;
    this.initialLogs = [];
    this.lambdaSimulatorService.invoke(this.functionNames.filter((f: any) => !f.IsDisabled).map((f1: any) => f1.Name)[0], this.number_of_instances).subscribe({
      next: (res: any) => {
        console.log(res);
        this.isRunBtnShouldDisabled = false;
      },
      error: error => {
        console.error('Error run on lambda', error);
        this.isRunBtnShouldDisabled = false;
        this.toastrNotificationService.errorsMessage("Invoke Lambda Simulator", "An error occurred!");
      }
    });

  }

  isMinusNeedsDisplay(i: string) {
    return this.clicks_lst.length > 1 && i !== "1";
  }

  hideElement(className: string): void {
    const element = document.getElementsByClassName(className)[0] as HTMLElement;
    if (element) {
      element.style.display = 'none';
    }
  }

  showElement(className: string): void {
    const element = document.getElementsByClassName(className)[0] as HTMLElement;
    if (element) {
      element.style.display = 'block';
    }
  }

  isNumberOfInstanceNotValid() {
    let notValid = this.number_of_instances < 1;
    return notValid;
  }

  hasDuplicates() {
    const values = Object.values(this.selectionDict);
    const uniqueValues = new Set(values);
    let notValid = values.length !== uniqueValues.size;
    return notValid;
  }

  onSelectLambdaCode(i: string, ev: any) {
    this.selectionDict[i] = ev.value;
  }

  onChangeNumber(ev: any) {
    this.number_of_instances = +ev.target.value;
  }
}
