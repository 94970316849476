import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private route = "config";

  constructor(private apiService: ApiService) { }

  getContactInformation() {
    return this.apiService.getRequest(this.route + '/contact-information');
  }
}
