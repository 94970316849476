import { Component, OnInit } from '@angular/core';
import { PopupType } from 'src/app/models/Project';
import Site from 'src/app/models/Site';
import { ProjectSitesService } from 'src/app/services/projectSites/project-sites.service';
import { SiteService } from 'src/app/services/site/site.service';
import { ToastrNotificationsService } from 'src/app/services/toastr-notifications/toastr-notifications.service';

@Component({
  selector: 'app-sites-management',
  templateUrl: './sites-management.component.html',
  styleUrls: ['./sites-management.component.scss']
})
export class SitesManagementComponent implements OnInit {
  sitesList: Site[] = [];
  sites: Site[] = [];
  showSitePopup: boolean = false;
  showDeleteSitePopup: boolean = false;
  sitePopupType: PopupType = PopupType.create;
  selectedSite: Site;
  editSiteId: string = "";
  isLoading: boolean = true;
  contentMessageForSitePopup: string = ""
  site_id: string;
  numberSitePagination: number = 1;

  constructor(private siteService: SiteService, private toastrNotificationService: ToastrNotificationsService, private projectSitesService: ProjectSitesService) { }

  ngOnInit(): void {
    this.getSitesList();
  }

  getSitesList() {
    this.siteService.getAll().subscribe({
      next: (res: any) => {
        if (res && res["sites"]) {
          this.sitesList = res["sites"].map((site: any) => new Site(site));
          this.sites = res["sites"].map((site: any) => new Site(site));
          this.isLoading = false;
        }
      },
      error: error => {
        console.error('Error get sites', error);
        this.toastrNotificationService.errorsMessage("Get Sites", "An error occurred!");
        this.isLoading = false;
      }
    });
  }

  closeSitePopup(statusReloadData: boolean) {
    if (statusReloadData) {
      this.getSitesList();
    }
    this.showSitePopup = false;
  }

  openCreateSitePopup() {
    this.sitePopupType = PopupType.create;
    this.showSitePopup = true;
    this.selectedSite = new Site({ id: '', name: '', manager_name: '', manager_phone: '', manager_email: '' });
  }

  openUpdateSitePopup(site: Site) {
    this.selectedSite = site;
    this.sitePopupType = PopupType.edit;
    this.showSitePopup = true;
  }

  deleteSite(id: string) {
    this.projectSitesService.isSiteExists(id).subscribe({
      next: (res1: any) => {
        if (res1.isExists) {
          //cannot remove
          this.toastrNotificationService.errorsMessage("Cannot Remove Site", "This site is assigned for a project!");
        } else {
          this.siteService.delete(id).subscribe({
            next: (res: any) => {
              this.getSitesList();
              this.toastrNotificationService.successMessage("Delete Site", "Deleted site successfully!");
            },
            error: error => {
              console.error('Error get sites', error);
              this.toastrNotificationService.errorsMessage("Delete Site", "An error occurred!");
            }
          });
        }
      },
      error: error => {
        console.error('Error get Projects', error);
        this.toastrNotificationService.errorsMessage("Get Projects", "An error occurred!");
      }
    });

  }

  openDeleteSitePopup(siteId: string) {
    this.site_id = siteId;
    let current_site = this.sites.find(s => s.id == siteId);
    let site_name = current_site?.name;
    this.contentMessageForSitePopup = `Are you sure you want to delete the \"#${siteId} - ${site_name}\" site?`;
    this.showDeleteSitePopup = true;
  }

  closeDeleteSitePopup(statusReloadData: boolean) {
    if (statusReloadData) {
      this.deleteSite(this.site_id);
    }
    this.showDeleteSitePopup = false;
  }

  onSearchChange(sitesToDisplay: Site[]) {
    this.sites = sitesToDisplay;
  }
}
