import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserManagementService } from '../userManagementService/user-management.service';
import { EMPTY, Observable, catchError, tap, throwError } from 'rxjs';
import { ToastrNotificationsService } from '../toastr-notifications/toastr-notifications.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService implements HttpInterceptor {
  private host = environment.host;
  private staticHost = environment.staticRequestsHost;

  constructor(private http: HttpClient, private userManagementService: UserManagementService, private toastrNotificationService: ToastrNotificationsService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if(error.status == 401 || error.status == 403){
          this.toastrNotificationService.errorsMessage("Unauthorized", "It's been a long time since you logged in, please refresh the page", true);
          return EMPTY;
        }
        return throwError(() => error);
      })
    );
      
  }

  getRequest(path: string) {
    return this.http.get(`${this.host}/${path}`, { headers: this.getHeaders() });
  }
  getStaticBlobRequest(path: string) {
    return this.http.get(`${this.staticHost}${path}`, { responseType: 'blob' as 'json', headers: this.getHeaders() })
  }
  putRequest(path: string, body: any) {
    return this.http.put(`${this.host}/${path}`, body, { headers: this.getHeaders() });
  }

  deleteRequest(path: string) {
    return this.http.delete(`${this.host}/${path}`, { headers: this.getHeaders() });
  }

  deleteRequestWithBody(path: string, body: any) {
    return this.http.delete(`${this.host}/${path}`, { body: body, headers: this.getHeaders() });
  }

  postRequest(path: string, body: any) {
    return this.http.post(`${this.host}/${path}`, body, { headers: this.getHeaders() });
  }

  postRequestFormData(path: string, formData: FormData) {
    return this.http.post(`${this.host}/${path}`, formData, { headers: this.getHeaders() });
  }

  postRequestWithRes(path: string, body: any, options: any) {
    return this.http.post(`${this.host}/${path}`, body, options);
  }

  getHeaders() {
    const user = this.userManagementService.getCurrentUser();
    if (!user) {
        return undefined;
    }
    return {
        'id-token': user.idToken
    };
  }
}
