<app-header></app-header>
<div class="page agents">
    <div class="agents-wrap">
        <div class="title">
            <div class="d-flex">
                <div>
                    <h2>Lambda Concurrency Simulator</h2>
                </div>
            </div>
        </div>
        <div class="content">
            <form>
                <div class="rows">
                    <div class="d-block" *ngFor="let i of clicks_lst">
                        <button class="circle-add-btn" [disabled]="aws_code_lambda.length == clicks_lst.length"
                            (click)="addRow()">+</button>
                        <mat-form-field [appearance]="'fill'">
                            <mat-label>Lambda Code</mat-label>
                            <mat-select [id]="i" matNativeControl required [value]="aws_code_lambda[0]" (selectionChange)="onSelectLambdaCode(i, $event)">
                                <mat-option [value]="code.Name" [disabled]="code.IsDisabled" *ngFor="let code of functionNames">{{code.Name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button class="circle-minus-btn" (click)="minusRow()" *ngIf="isMinusNeedsDisplay(i)">-</button>
                    </div>
                </div>
                <div>
                    <mat-form-field [appearance]="'fill'">
                        <mat-label>Number of instances</mat-label>
                        <input matInput [value]="number_of_instances" (keyup)="onChangeNumber($event)">
                    </mat-form-field>
                </div>
                <mat-action-list>
                    <button (click)="runLambda()" [disabled]="isRunBtnShouldDisabled || isNumberOfInstanceNotValid() || hasDuplicates()">Run</button>
                    <span *ngIf="isRunBtnShouldDisabled " class="loader"></span>
                    <div class="errors">
                        <span class="number-err" *ngIf="isNumberOfInstanceNotValid()">* Number of instances should be equal or greater than 1.</span>
                        <span class="duplicates-err" *ngIf="hasDuplicates()">* Please remove duplicates from lambda code.</span>
                    </div>
                </mat-action-list>
            </form>
            <div class="lambda-status" *ngIf="isClickedOnTheRunBtn">
                <mat-label class="lambda-title">Lambda Status</mat-label>
                 <app-log-viewer class="lambdaStatusLogViewer" [logs]="initialLogs"></app-log-viewer>
            </div>
        </div>
    </div>
</div>
