import { Injectable } from '@angular/core';
import ProjectSite from 'src/app/models/ProjectSite';
import { ApiService } from '../api/api.service';
import { Type } from 'src/app/models/Run';
import { ToastrNotificationsService } from '../toastr-notifications/toastr-notifications.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {
    private path = "file";

    constructor(private apiService: ApiService, private toastrNotificationsService: ToastrNotificationsService) { }

    downloadFile(path: string, filename?: string): void{
        this.apiService.getStaticBlobRequest(path).subscribe({
            next: (response: any) => {
                let dataType = response.type;
                let binaryData = [];
                binaryData.push(response);
                let downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
                if (filename) {
                    downloadLink.setAttribute('download', filename);
                }
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            },
            error: (error) => {
                console.error(`Error while download file. error: ${error}`);
                this.toastrNotificationsService.errorsMessage("File download", "An error occurred!");
            }
        });
    }

    uploadInputFileProjectSite(uploadFile: File, projectSite: ProjectSite){
        const formData = new FormData();
        formData.append("file", uploadFile);
        return this.apiService.postRequestFormData(`${this.path}/upload-input-file/${projectSite.id}`, formData);
    }

    predictFile(fileToPredict: File, runId: number, type: Type) {
        const formData = new FormData();
        formData.append("file", fileToPredict);
        return this.apiService.postRequestFormData(`${this.path}/${type}/predict/${runId}`, formData);
    }
}
