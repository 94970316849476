import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import Agents, { FilePurpose } from 'src/app/models/Agents';
import { Role } from 'src/app/models/User';
import { AgentsService } from 'src/app/services/agents/agents.service';
import { FileService } from 'src/app/services/file/file.service';
import { ToastrNotificationsService } from 'src/app/services/toastr-notifications/toastr-notifications.service';
import { UserManagementService } from 'src/app/services/userManagementService/user-management.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.scss']
})
export class AgentsComponent implements OnInit {

  constructor(
    private sanitizer: DomSanitizer,
    private agentService: AgentsService,
    private toastrNotificationService: ToastrNotificationsService,
    private userManagementService: UserManagementService,
    private fileService: FileService
  ) { }

  @ViewChild(MatMenuTrigger) contextMenu!: MatMenuTrigger;
  collapseMore: boolean = true;
  agentsList: Agents[] = [];
  agents: Agents[] = [];
  generalAgentsList: Agents[] = [];
  generalAgents: Agents[] = [];
  isLoading: boolean = true;
  isGeneralContextMenu: boolean = false;
  contextMenuPosition = { x: '0px', y: '0px' };
  editedAgent: Agents;
  userRole?: Role;
  fileLink: SafeResourceUrl | null;
  fileName: string;
  showCreateAgentPopup: boolean;
  showDeleteAgentPopup: boolean = false;
  deletePopupContent: string;
  iconHost = environment.staticRequestsHost;

  get role(): typeof Role {
    return Role;
  }

  get filePurpose(): typeof FilePurpose {
    return FilePurpose;
  }

  ngOnInit(): void {
    this.userRole = this.userManagementService.getCurrentUser()?.role;
    this.initAgents();
  }

  initAgents(): void {
    this.agentService.getAll().subscribe({
      next: (res: any) => {
        if (res && res.projectsAgents) {
          this.agentsList = res.projectsAgents.map((agent: any) => {
            let agent_ = new Agents(agent);
            return agent_;
          });
          this.agents = this.agentsList;
        }
        if (res && res.generalAgents) {
          this.generalAgentsList = res.generalAgents.map((agent: any) => new Agents(agent));
          this.generalAgents = this.generalAgentsList.slice();
        }
        if (this.agentsList.length === 0 && this.generalAgentsList.length > 0) {
            this.collapseMore = false;
        }
        this.isLoading = false;
      },
      error: error => {
        console.error('Error get agents', error);
        this.toastrNotificationService.errorsMessage("Get Agents", "An error occurred!");
        this.isLoading = false;
      }
    });
  }

  getMimeType(extension: string) {
    if (extension == "py") {
      return "text/x-python";
    } else if (extension == "pdf") {
      return "application/pdf";
    } else if (extension == "exe") {
      return "application/x-msdownload"
    } else if (extension == "msi") {
      return "application/x-ms-installer"
    } else if (extension == "zip") {
      return "application/zip"
    }
    return "";
  }

  onSearchChange(agentsToDisplay: Agents[]) {
    this.agents = agentsToDisplay;
  }

  onSearchOfGeneralChange(agentsToDisplay: Agents[]) {
    this.generalAgentsList = agentsToDisplay;
  }

  getUrlObject(blob: Blob) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
  }

  downloadTutorialDocument(agent_id: string) {
    this.agentService.getTutorialDocument(agent_id).then(res => {
      let current_agent_index = this.agents.findIndex(agent => agent.id == agent_id);
      let installationInstruction = (res as any).installationsInstruction;
      this.agents[current_agent_index].installation_instruction = new File(
        [new Blob([new Uint8Array(installationInstruction.installations_instruction_content.data).buffer])],
        installationInstruction.installations_instruction_name,
        { type: this.getMimeType(installationInstruction.installations_instruction_name.split('.').pop()) }
      );
      this.fileLink = this.getUrlObject(this.agents[current_agent_index].installation_instruction);
      this.fileName = this.agents[current_agent_index].installation_instruction.name;
      setTimeout(() => document.getElementById("hidden-download-button")?.click(), 5);
    }).catch(error => {
      console.error(`error while get tutorial document. error: ${error}`);
      this.toastrNotificationService.errorsMessage("Get Tutorial Document of Agent", "An error occurred!");
    });
  }

  downloadMasterFile(agent_id: string) {
    this.agentService.getMasterFile(agent_id).then(res => {
      let current_agent_index = this.agents.findIndex(agent => agent.id == agent_id);
      let masterFile = (res as any).masterFile;
      this.agents[current_agent_index].master_file = new File(
        [new Blob([new Uint8Array(masterFile.master_file_content.data).buffer])],
        masterFile.master_file_name,
        { type: this.getMimeType(masterFile.master_file_name.split('.').pop()) }
      );
      this.fileLink = this.getUrlObject(this.agents[current_agent_index].master_file);
      this.fileName = this.agents[current_agent_index].master_file.name;
      setTimeout(() => document.getElementById("hidden-download-button")?.click(), 5);
    }).catch(error => {
      console.error(`error while get master file. error: ${error}`);
      this.toastrNotificationService.errorsMessage("Get Master File of Agent", "An error occurred!");
    });
  }

  downloadAgentFile(agent: Agents, type: FilePurpose) {
    if (type == FilePurpose.agentLinux && !agent.agent_file_linux)
      return;
    if (type == FilePurpose.agentWindows && !agent.agent_file_windows)
      return;
    if (type == FilePurpose.agentMac && !agent.agent_file_mac)
      return;
    this.agentService.getAgentFile(agent.id, type).then(res => {
      let folderName = "";
      if (type == FilePurpose.agentLinux) {
        folderName = "/AgentsLinuxFiles/";
      } else if (type == FilePurpose.agentWindows) {
        folderName = "/AgentsWindowsFiles/"
      } else if (type == FilePurpose.agentMac) {
        folderName = "/AgentsMacFiles/";
      }

      let agentFileName = res.split(folderName).pop();
      this.fileService.downloadFile(res, agentFileName);
      this.toastrNotificationService.infoMessage("Agent download", "The agent is downloading, please wait");
    }).catch(error => {
      console.error(`error while get agent file. error: ${error}`);
      this.toastrNotificationService.errorsMessage("Agent download", "An error occurred!");
    });
  }

  editAgent(agent: any) {
    this.editedAgent = agent;
    this.showCreateAgentPopup = true;
  }

  openContextMenu(event: MouseEvent, agent?: Agents) {
    event.preventDefault();
    this.isGeneralContextMenu = !agent;
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'agent': agent };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  openCreateAgentPopup() {
    this.editedAgent = new Agents();
    this.showCreateAgentPopup = true;
  }

  createAgentPopupClosed(event: { updated: boolean, payload?: any }) {
    if (event.updated) {
      this.initAgents();
    }
    this.showCreateAgentPopup = false;
  }

  openDeletePopup(agent: Agents) {
    this.editedAgent = agent;
    this.deletePopupContent = `Are you sure you want to delete the "${agent.name}" agent?`;
    this.showDeleteAgentPopup = true;
  }

  closeDeletePopup(toDelete: boolean) {
    if (toDelete) {
      this.deleteAgent();
    }
    this.showDeleteAgentPopup = false;
  }

  deleteAgent() {
    this.agentService.delete(this.editedAgent.id).subscribe({
      next: (res: any) => {
        this.initAgents();
        this.toastrNotificationService.successMessage("Delete Agent", "Deleted agent successfully!");
      },
      error: (error: any) => {
        console.error('Error delete agent', error);
        this.toastrNotificationService.errorsMessage("Delete Agent", "An error occurred!");
      }
    });
  }
}