import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Role } from 'src/app/models/User';
import { CognitoAwsService } from 'src/app/services/cognito-aws/cognito-aws.service';
import { UserManagementService } from 'src/app/services/userManagementService/user-management.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  userRole?: Role;
  userHasProjects: boolean = false;
  activeUrl: string = "";

  get role(): typeof Role {
    return Role; 
  }

  constructor(
    private router: Router, 
    private cognitoAwsService: CognitoAwsService,
    private userManagementService: UserManagementService
  ) { }

  ngOnInit(): void {
    this.activeUrl = this.router.url;
    this.userRole = this.userManagementService.getCurrentUser()?.role;
    this.userHasProjects = (this.userManagementService.getCurrentUser()?.projects_ids || []).length > 0;
  }

  goToPage(name: string) {
    this.router.navigate(['/' + name]);
  }

  logout() {
    this.cognitoAwsService.signOut();
  }
}
