<app-header></app-header>
<div class="page sites">
   
    <div class="title">
        <div class="d-flex">
        <h2>Sites | <span class="count">{{ sites.length + ' out of ' + sitesList.length }}</span></h2>
        <app-search *ngIf="sitesList &&sitesList.length" [items]="sitesList" [searchKeys]="['id', 'name']" (itemsListChange)="onSearchChange($event)"></app-search>
        </div>
        <button (click)="openCreateSitePopup()">
            + New Site
        </button>
    </div>
    <div class="table-wrap">
        <table *ngIf="sites && sites.length">
            <tr>
                <th>Site Id</th>
                <th>Site Name</th>
                <th>Contact Name</th>
                <th>Contact Phone</th>
                <th>Contact Email</th>
                <th></th>
                <th></th>
            </tr>
            <tr *ngFor="let site of sites | paginate: { itemsPerPage: 7, currentPage: numberSitePagination }">
                <td>{{site.id}}</td>
                <td>{{site.name}}</td>
                <td>{{site.manager_name}}</td>
                <td>{{site.manager_phone}}</td>
                <td>{{site.manager_email}}</td>
                <td class="action"><img src="assets/Icons/editing.svg" (click)="openUpdateSitePopup(site)" /></td>
                <td class="action"><img src="assets/Icons/delete.png" (click)="openDeleteSitePopup(site.id)"/></td>
            </tr>
        </table>
        <pagination-controls (pageChange)="numberSitePagination = $event" *ngIf="sites.length > 7"></pagination-controls>
        <div class="empty-wrap" *ngIf="!sitesList || !sitesList.length">
            <h2>You don’t have data sites. Start adding by clicking the button above.</h2>
            <h3>The sites be listed here.</h3>
            <img alt="empty projects list" src="assets/Icons/project-list-empty.png" />
        </div>
    </div>
    <app-site-popup *ngIf="showSitePopup" (closePopupEvent)="closeSitePopup($event)" [type]="sitePopupType" [siteId]="selectedSite.id"></app-site-popup>
    <app-delete-popup *ngIf="showDeleteSitePopup" (closePopupEvent)="closeDeleteSitePopup($event)" [title]="'Delete Site'" [content]="contentMessageForSitePopup"></app-delete-popup>
</div>
<div class="custom-spinner" *ngIf="isLoading">
    <app-spinner></app-spinner>
</div>