import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {
    @Input() items: any[] = [];
    @Input() searchKeys: any[] = [];
    @Output() itemsListChange = new EventEmitter<any[]>();
    searchText = '';

    ngOnChanges(changes: SimpleChanges) {
        if (changes['items']) {
            this.searchText = '';
        }
    }

    onSearchChange() {
        this.itemsListChange.emit(
            this.items.filter(item => 
                this.searchKeys.some(key => 
                    item[key].toString().toLowerCase().includes(this.searchText.toLowerCase())
                )
            )
            .slice()
        );
    }
}
