<div class="mask-popup" (click)="closePopup()">
    <div class="content" *ngIf="project" (click)="$event.stopPropagation();">
        <div class="wrap-title">
            <h1>{{project.name}}</h1>
            <img alt="close" src="assets/Icons/close.png" (click)="closePopup()" />
        </div>
        <div class="info">{{project.info}}</div>
        <div class="section-title">Contact</div>
        <table>
            <tr>
                <td class="icon"><img src="assets/Icons/person.svg"/></td>
                <td class="field-name">Name</td>
                <td>{{project.contact_name}}</td>
            </tr>
            <tr>
                <td class="icon"><img src="assets/Icons/telephone.svg"/></td>
                <td class="field-name">Phone</td>
                <td>{{project.contact_phone}}</td>
            </tr>
            <tr>
                <td class="icon"><img src="assets/Icons/envelope.svg"/></td>
                <td class="field-name">Email</td>
                <td><a href="mailto:{{project.contact_email}}" target="_blank">{{project.contact_email}}</a></td>
            </tr>
        </table>
        <ng-container *ngIf="project.lastSuccessRunId">
            <div class="section-title">Output File</div>
            <table>
                <tr>
                    <td class="field-name">Start Date</td>
                    <td>{{project.lastSuccessRunStartDate}}</td>
                </tr>
                <tr>
                    <td class="field-name">End Date</td>
                    <td>{{project.lastSuccessRunEndDate}}</td>
                </tr>
            </table>
            <div class="buttons">
                <div *ngIf="project.lastSuccessRunType !== runType.pyTorch" class="download" (click)="downloadOutputFile()">Download Consolidated File</div>
                <div *ngIf="project.lastSuccessRunType === runType.pyTorch" class="download" (click)="downloadOutputFile(outputType.script)">Download Script File</div>
                <div *ngIf="project.lastSuccessRunType === runType.pyTorch" class="download" (click)="downloadOutputFile(outputType.application)">Download Application File</div>
            </div>
        </ng-container>
    </div>
</div>