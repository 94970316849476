import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss']
})
export class DeletePopupComponent {
  @Input() title: string;
  @Input() content: string;
  @Output() closePopupEvent = new EventEmitter<boolean>();

  closePopup(isToDelete: boolean) {
    this.closePopupEvent.emit(isToDelete);
  }

}
