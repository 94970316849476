<div class="mask-popup" *ngIf="projectForm" (click)="closePopup()" > 
    <form class="content" [formGroup]="projectForm" (ngSubmit)="onSubmit()" (click)="$event.stopPropagation();">
        <div class="wrap-title">
            <h1 *ngIf="type == 'create'">Create Project</h1>
            <h1 *ngIf="type == 'edit'">Edit Project {{project.id}}</h1>
            <img alt="close" src="assets/Icons/close.png" (click)="closePopup()" />
        </div>
        <div class="wrap-field">
            <div class="label">Name<span class="error">*</span></div>
            <input type="text" placeholder="Type project name" formControlName="name" id="name" required
                (keyup)="onTypingProjectName()" />
            <span class="error"
                *ngIf="projectFormControl['name'].touched  && projectFormControl['name'].errors?.['required']">
                Name is required
            </span>
        </div>
        <div class="wrap-field">
            <div class="label">Info<span class="error">*</span></div>
            <textarea type="text" placeholder="Type project info" formControlName="info" id="info" required></textarea>
            <span class="error"
                *ngIf="projectFormControl['info'].touched  && projectFormControl['info'].errors?.['required']">
                Info is required
            </span>
        </div>
        <div class="wrap-field">
            <div class="label">Contact Name<span class="error">*</span></div>
            <input type="text" placeholder="Type contact name" formControlName="contact_name" id="contact_name"
                required />
            <span class="error"
                *ngIf="projectFormControl['contact_name'].touched  && projectFormControl['contact_name'].errors?.['required']">
                Contact name is required
            </span>
        </div>
        <div class="wrap-field">
            <div class="label">Contact Phone<span class="error">*</span></div>
            <input type="phone" placeholder="Type contact phone" formControlName="contact_phone" id="contact_phone"
                required />
            <span class="error"
                *ngIf="projectFormControl['contact_phone'].touched  && projectFormControl['contact_phone'].errors?.['required']">
                Contact phone is required
            </span>
            <span class="error" *ngIf="projectFormControl['contact_phone'].errors?.['pattern']">Please provide a valid
                phone number</span>
        </div>
        <div class="wrap-field">
            <div class="label">Contact Email<span class="error">*</span></div>
            <input type="text" type="email" placeholder="Type contact email" formControlName="contact_email"
                id="contact_email" required />
            <span class="error"
                *ngIf="projectFormControl['contact_email'].touched  && projectFormControl['contact_email'].errors?.['required']">
                Contact phone is required
            </span>
            <span class="error" *ngIf="projectFormControl['contact_email'].errors?.['pattern']">Please provide a valid
                email address</span>
        </div>

        <app-manage-project-sites [sitesList]="sitesList" [projectForm]="projectForm"></app-manage-project-sites>

        <div class="wrap-field">
            <div>
                <div class="label">Master File<span class="error">*</span></div>
                <div class="small">
                    A master file should include at least 2 lines: 
                    the first is the names of the columns in the input files and 
                    the second is the column type: date, number or free text.
                    The file can include a third line for federated learning that specifies what to do with the column: 
                    prediction (the column to predict), drop (a column to drop), text (to encode the column)</div>
                <div class="wrap-file">
                    <div class="wrap-input" id="uploadMasterFileDiv">
                        <input type="file" (change)="updateMasterFile($event)" accept=".csv" />
                        <div class="upload-input-btn">
                            {{ !project.master_file_id ? 'Upload File' : 'Replace File' }} <img alt="upload" src="assets/Icons/upload-gray.png" />
                        </div>
                    </div>
                    <div class="download-link" *ngIf="project.master_file_id && type == 'edit' && !fileToUpload">
                        <a (click)="downloadMasterFileEvent.emit(project)" id="master_file_id" style="display: flex;">Download master file</a>
                    </div>
                </div>
            </div>
            <span class="upload-path">{{fileToUpload?.name}}</span>
            <span class="error" *ngIf="showErrorRequiredMasterFile"> Master File is required </span>
            <span class="error" *ngIf="showErrorTypeMasterFile"> The file must be of type csv </span>
        </div>
        <div class="validations-errors">
            <span class="error" *ngIf="showErrorProjectNameAlreadyExists"> The project name already exists </span>
        </div>
        <div class="d-flex ">
            <button *ngIf="type == 'create'" type="submit">Create</button>
            <button *ngIf="type == 'edit'" type="submit">Update</button>
            <button class="white" (click)="closePopup()">Cancel</button>
        </div>
    </form>
</div>