import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { WebSocketService } from 'src/app/services/web-socket/web-socket.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-log-viewer',
  templateUrl: './log-viewer.component.html',
  styleUrls: ['./log-viewer.component.scss']
})
export class LogViewerComponent implements OnInit, OnChanges {
  @Input() logs: string[];
  public isEmpty = true;
  constructor(private wsService: WebSocketService) { }

  subscribe_websocket() {
    this.wsService.connect(environment.websocketHost).subscribe(event => {
      this.logs = event.data.split('\n');
      this.isEmpty = false;
    });

  }

  ngOnInit(): void {
    this.subscribe_websocket();
  }


  ngOnChanges(): void {
    this.subscribe_websocket();
  }
}
