import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Agents, { FilePurpose } from 'src/app/models/Agents';
import Project from 'src/app/models/Project';
import { AgentsService } from 'src/app/services/agents/agents.service';
import { ProjectService } from 'src/app/services/project/project.service';
import { ToastrNotificationsService } from 'src/app/services/toastr-notifications/toastr-notifications.service';

@Component({
  selector: 'app-new-agent-popup',
  templateUrl: './new-agent-popup.component.html',
  styleUrls: ['./new-agent-popup.component.scss']
})
export class NewAgentPopupComponent {
  @Output() closePopupEvent = new EventEmitter<{ updated: boolean, payload?: any }>();
  @Input() agent: Agents = new Agents();
  agentForm: FormGroup;
  projectsList: Project[] = [];
  filesToUpload: { [key in FilePurpose]: File | null } = {
    [FilePurpose.icon]: null,
    [FilePurpose.masterFile]: null,
    [FilePurpose.userGuide]: null,
    [FilePurpose.agentWindows]: null,
    [FilePurpose.agentLinux]: null,
    [FilePurpose.agentMac]: null
  };
  isLoading: boolean = false;

  get agentFormControl() {
    return this.agentForm.controls;
  }

  get filePurpose(): typeof FilePurpose {
    return FilePurpose;
  }

  get noAgentFiles(): boolean {
    return (!this.agent.agent_file_linux && !this.agent.agent_file_mac && !this.agent.agent_file_windows) &&
      (!this.filesToUpload[FilePurpose.agentWindows] && !this.filesToUpload[FilePurpose.agentMac] && !this.filesToUpload[FilePurpose.agentLinux]);
  }
  get noAgentLinuxFile(): boolean {
    return !this.agent.agent_file_linux && !this.filesToUpload[FilePurpose.agentLinux];
  }
  get noAgentWindowsFile(): boolean {
    return !this.agent.agent_file_windows && !this.filesToUpload[FilePurpose.agentWindows];
  }
  get noAgentMacFile(): boolean {
    return !this.agent.agent_file_mac && !this.filesToUpload[FilePurpose.agentMac];
  }

  get noUserGuide(): boolean {
    return !this.agent.installation_instruction && !this.filesToUpload[FilePurpose.userGuide];
  }

  constructor(
    private formBuilder: FormBuilder,
    private projectService: ProjectService,
    private agentsService: AgentsService,
    private toastrNotificationService: ToastrNotificationsService
  ) { }

  ngOnInit() {
    this.createForm();
    this.getProjects();
  }

  closePopup() {
    this.closePopupEvent.emit({ updated: false });
  }

  createForm() {
    this.agentForm = this.formBuilder.group({
      name: [this.agent.name, Validators.required],
      project_id: [this.agent.project.id || 0],
      os: [this.agent.os, Validators.required],
      memory: [this.agent.memory, Validators.required],
      compute: [this.agent.compute, Validators.required],
      pre_requirement_software: [this.agent.pre_requirement_software, Validators.required],
      libs: [this.agent.libs, Validators.required],
      expected_run_time: [this.agent.expected_run_time, Validators.required],
      explanation: [this.agent.explanation, Validators.required],
      installation_instruction: [this.agent.installation_instruction],
      agent_file_windows: [this.agent.agent_file_windows],
      agent_file_linux: [this.agent.agent_file_linux],
      agent_file_mac: [this.agent.agent_file_mac],
      master_file: [this.agent.master_file],
      icon: [this.agent.icon],
    });
  }

  getProjects() {
    this.projectService.getAll().subscribe({
      next: (res: any) => {
        if (res && res.projects) {
          this.projectsList = res.projects.map((project: any) => new Project(project));
        }
      },
      error: error => {
        console.error(`error while get projects. error: ${error}`);
        this.toastrNotificationService.errorsMessage("Get Projects", "An error occurred!");
      }
    });
  }

  onSubmit() {
    if (this.agentForm.invalid || this.noAgentFiles) {
      console.log('invalid');
      this.agentForm.markAllAsTouched();
      return;
    }
    this.isLoading = true;
    if (this.agent.id) {
      this.updateAgent();
    } else {
      this.createAgent();
    }
  }

  createAgent() {
    this.agentsService.createAgent(this.filesToUpload, this.agentForm.getRawValue()).subscribe({
      next: () => {
        this.isLoading = false;
        this.closePopupEvent.emit({ updated: true, payload: this.agentForm.getRawValue() });
        this.toastrNotificationService.successMessage("Create Agent", "Agent successfully created!");
      },
      error: error => {
        this.isLoading = false;
        console.error('Error create agent', error);
        this.toastrNotificationService.errorsMessage("Create Agent", "An error occurred!");
      }
    });
  }

  updateAgent() {
    const payload = {
      ...this.agentForm.getRawValue(),
      id: this.agent.id
    };
    this.agentsService.updateAgent(this.filesToUpload, payload).subscribe({
      next: () => {
        this.isLoading = false;
        this.closePopupEvent.emit({ updated: true, payload });
        this.toastrNotificationService.successMessage("Update Agent", "The agent was successfully updated!");
      },
      error: error => {
        this.isLoading = false;
        console.error('Error update agent', error);
        this.toastrNotificationService.errorsMessage("Update Agent", "An error occurred!");
      }
    });
  }

  updateFile(filesEvent: Event, forWhat: FilePurpose) {
    let files = (filesEvent.target as HTMLInputElement).files;
    if (files && files.length > 0) {
      let file = files[0];
      this.filesToUpload[forWhat] = file;
    }
  }
}