import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../api/api.service';
import { Type } from '../../models/Run';
import { OutputType } from 'src/app/models/OutputFile';

@Injectable({
  providedIn: 'root',
})
export class RunService {
    route = 'run';
  
    constructor(private apiService: ApiService) {}

    async getOutputFileDownloadLink(runId: number, outputType?: OutputType) {
        const response: any = await firstValueFrom(
            this.apiService.getRequest(`${this.route}/download-output-file/${runId}?${outputType? `outputType=${outputType.toString()}`: ''}`)
        );
        return response.downloadLink;
    }

    sendOutputFileToChatGPT(runId: number) {
        return this.apiService.getRequest(`${this.route}/send-file-rag/${runId}`);
    }

    getLogsAndRunDetailsByRunId(runId: string){
        return this.apiService.getRequest(this.route + `/${runId}`);
    }
    
    runProcess(projectId: string, type: Type){
        return this.apiService.postRequest(this.route + `/${type}/${projectId}`, null);
    }
    
    stopRun(runId: number){
        return this.apiService.putRequest(this.route + `/stop/${runId}`, null);
    }
}
