<div class="mask-popup run-popup" (click)="closePopup()">
    <div class="content" (click)="$event.stopPropagation();">
        <div class="title">
            Run process
        </div>
        <mat-progress-bar mode="indeterminate" [color]="'#7003e1'" *ngIf="!runDetails || !runDetails.status"></mat-progress-bar>
        <div class="logs-wrap" id="logs-id" *ngIf="logs.length">
            <div class="log" *ngFor="let log of logs">
                <div class="time">[{{getDateFormat(log.created_at)}} <span class="status" [class]="log.status">{{log.status}}</span>]</div>
                <div class="message">{{log.message}}</div>           
            </div>
        </div>
        <div class="bottom">
            <button (click)="openChatGPT()" *ngIf="runDetails && runDetails.status == runStatus.success && runDetails.type == runType.RAG"> Open With  {{chatModelLLM}} </button>
            <button class="white" (click)="stopProcess()" *ngIf="runId && (!runDetails || !runDetails.status)">STOP</button>
            <button class="white" (click)="closePopup()" *ngIf="runDetails && runDetails.status">CLOSE</button>
        </div>
        </div>
</div>