<form [formGroup]="projectForm">
    <div class="wrap-field">
        <div class="label">Sites<span class="error">*</span></div>
        <div class="wrap-table-sites">
            <div class="header-table">
                <div class="left">Site</div>
                <div class="right">Path</div>
            </div>
            <div class="content-table" formArrayName="sites">
                <div *ngFor="let siteProject of  siteFormGroup; let i=index">
                    <div class="row" [formGroupName]="i">
                        <mat-form-field>
                            <mat-select formControlName="site_id">
                                <mat-option *ngFor="let site of sitesList"
                                [value]="site.id" [disabled]="siteIsDisabled(site, i)">{{site.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="right">
                            <div class="wrap-flex">
                                <input type="text" formControlName="link_to_retrieve" placeholder="Type path site" />
                                <img *ngIf="sitesList && siteFormGroup.length > 1" (click)="removeProjectSite(i)"
                                    alt="delete" src="assets/Icons/close-tooltip.svg" />
                            </div>
                            <span class="error"
                                *ngIf="getFormSiteByIndex(i)['link_to_retrieve'].errors?.['pattern']">Please provide a
                                valid
                                link</span>

                        </div>
                    </div>
                    <span class="error"
                        *ngIf="firstFormSite['site_id'].touched  && firstFormSite['site_id'].errors?.['required']">
                        Site is required
                    </span>
                </div>
                <div class="add-site" (click)="addProjectSite()"
                    *ngIf="siteFormGroup && sitesList && siteFormGroup.length < sitesList.length">+ Add Site</div>
            </div>
        </div>
        <div class="small">Google Drive files should be shared with {{email}}</div>
    </div>
</form>