import { Injectable } from '@angular/core';
import ProjectSite from 'src/app/models/ProjectSite';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectSitesService {
  private path = "project-site";
  
  constructor(private apiService: ApiService) { }

  update(projectSite: ProjectSite) {
    return this.apiService.putRequest(this.path + `/${projectSite.id}`, { project_site: projectSite });
  }

  retrieveInputFileProjectSite(projectSiteId: string) {
    return this.apiService.getRequest(this.path + `/retrieve/${projectSiteId}`);
  }

  isSiteExists(siteId: string) {
    return this.apiService.getRequest(this.path + `/is-site-exists/${siteId}`);
  }
}
