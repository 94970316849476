<div class="menuDiv">
  <img [matMenuTriggerFor]="menu" src="assets/Icons/menu-bar.png" />
  <mat-menu #menu="matMenu" [class]="'custom-panel-menu'">
    <button mat-menu-item *ngIf="userRole === role.Admin" [class.selected]="activeUrl === '/projects'" (click)="goToPage('projects')">
      <img src="assets/Icons/projects.png" />
      Projects
    </button>
    <button mat-menu-item *ngIf="userRole === role.Admin" [class.selected]="activeUrl === '/user-projects'" (click)="goToPage('user-projects')">
      <img src="assets/Icons/ancient-scroll.png" />
      User Projects
    </button>
    <button mat-menu-item *ngIf="userRole === role.User" [class.selected]="activeUrl === '/user-projects'" (click)="goToPage('user-projects')">
      <img src="assets/Icons/projects.png" />
      Projects
    </button>
    <button mat-menu-item *ngIf="userRole === role.Admin" [class.selected]="activeUrl === '/users'" (click)="goToPage('users')">
      <img src="assets/Icons/users.png" />
      Users Management
    </button>
    <button mat-menu-item *ngIf="userRole === role.Admin" [class.selected]="activeUrl === '/sites'" (click)="goToPage('sites')">
      <img src="assets/Icons/management.png" />
      Sites Management
    </button>
    <button mat-menu-item *ngIf="userRole === role.Admin || userHasProjects" [class.selected]="activeUrl === '/agents'" (click)="goToPage('agents')">
      <img src="assets/Icons/download.png" />
      Agents
    </button>
    <button mat-menu-item (click)="logout()">
      <img src="assets/Icons/logout.png" />
      Log Out
    </button>
  </mat-menu>
</div>