import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { PopupType } from 'src/app/models/Project';
import Site from 'src/app/models/Site';
import { SiteService } from 'src/app/services/site/site.service';
import { ToastrNotificationsService } from 'src/app/services/toastr-notifications/toastr-notifications.service';

@Component({
  selector: 'app-site-popup',
  templateUrl: './site-popup.component.html',
  styleUrls: ['./site-popup.component.scss']
})
export class SitePopupComponent {
  siteForm: FormGroup;
  site: Site = new Site();
  @Input() siteId: string;
  @Output() createSitePopupEvent = new EventEmitter<Site>();
  @Output() closePopupEvent = new EventEmitter<boolean>();
  @Input() type = PopupType.create;
  showErrorSiteNameAlreadyExists: boolean = false;
  constructor(private formBuilder: FormBuilder, private siteService: SiteService, private toastrNotificationService: ToastrNotificationsService) {

  }

  ngOnInit(): void {
    if (this.type == PopupType.create) {
      this.createForm();
    } else {
      this.getSiteDetailsById();
    }

  }

  async onSubmit() {
    this.showErrorSiteNameAlreadyExists = false;
    this.siteForm.markAllAsTouched();
    if (this.siteForm.valid) {
      let siteBeforeChange = { ...this.site };
      try {
        if (this.type == PopupType.create || siteBeforeChange.name.toLocaleLowerCase() !== this.siteForm.value.name.toLocaleLowerCase()) {
          const siteNameExist = await firstValueFrom(this.siteService.isSiteExists(this.siteForm.value.name)) as { IsExists: boolean };
          if (siteNameExist.IsExists) {
            this.showErrorSiteNameAlreadyExists = true;
            return;
          }
        }
        if (this.type == PopupType.create) {
          this.createSite();
        } else {
          this.updateSite();
        }
      } catch (error) {
        console.error('Error is site exists', error);
        this.toastrNotificationService.errorsMessage("Create or update site", "An error occurred!");
      }
      console.log('Your form data : ', this.siteForm.value);
    }
  }

  createForm() {
    this.siteForm = this.formBuilder.group({
      id: [this.site.id],
      name: [this.site.name, Validators.required],
      manager_name: [this.site.manager_name, Validators.required],
      manager_phone: [this.site.manager_phone, [Validators.required, Validators.pattern("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$")]],
      manager_email: [this.site.manager_email, [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
      sftp_username: [this.site.sftp_username],
      sftp_password: [this.site.sftp_password],
    });
  }

  get siteFormControl() {
    return this.siteForm.controls;
  }


  closePopup() {
    this.closePopupEvent.emit(false);
  }

  getSiteDetailsById() {
    this.siteService.getSiteById(this.siteId).subscribe({
      next: (res: any) => {
        if (res && res.sites) {
          this.site = new Site(res.sites);
          this.createForm();
        }
      },
      error: error => {
        console.error('Error get site by id', error);
        this.toastrNotificationService.errorsMessage("Get Site", "An error occurred!");
      }
    });
  }

  createSite() {
    this.siteService.create(this.siteForm.value).subscribe({
      next: (res: any) => {
        this.closePopupEvent.emit(true);
        this.toastrNotificationService.successMessage("Create Site", "Created site successfully!");
      },
      error: error => {
        console.error('Error save', error);
        this.toastrNotificationService.errorsMessage("Create Site", "An error occurred!");
      }
    });

  }

  updateSite() {
    this.siteService.update(this.siteForm.value).subscribe({
      next: (res: any) => {
        this.closePopupEvent.emit(true);
        this.toastrNotificationService.successMessage("Update Site", "Updated site successfully!");
      },
      error: error => {
        console.error('Error save', error);
        this.toastrNotificationService.errorsMessage("Update Site", "An error occurred!");
      }
    });
  }

  onTypingSiteName() {
    this.showErrorSiteNameAlreadyExists = false;
  }
}
