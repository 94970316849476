import { Component, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import User, { Role } from 'src/app/models/User';
import { ToastrNotificationsService } from 'src/app/services/toastr-notifications/toastr-notifications.service';
import { UserService } from 'src/app/services/user/user.service';
import { UserManagementService } from 'src/app/services/userManagementService/user-management.service';

@Component({
    selector: 'app-users-management',
    templateUrl: './users-management.component.html',
    styleUrls: ['./users-management.component.scss']
})
export class UsersManagementComponent {
    @ViewChild(MatMenuTrigger) contextMenu!: MatMenuTrigger;
    contextMenuPosition = { x: '0px', y: '0px' };
    allUsers: User[] = [];
    users: User[] = [];
    selectedUsersIds: string[] = [];
    showEditPopup: boolean = false;
    showDeletePopup: boolean = false;
    editedUsers?: User[];
    deletedUsers?: User[];
    isLoading: boolean = true;
    numberUsersPagination: number = 1;
    titleForDeletePopup: string = "";
    contentMessageForDeletePopup: string = "";

    constructor(private userService: UserService, private toastrNotificationService: ToastrNotificationsService, private userManagementService: UserManagementService) { }

    get selectAllChecked(): boolean {
        return this.users.length > 0 && this.users.every(user => this.selectedUsersIds.includes(user.id));
    }

    getCurrentUserId(){
        return this.userManagementService.getCurrentUser()?.id;
    }

    ngOnInit() {
        this.getUsers();
    }

    getUsers() {
        this.userService.getUsers().then(res => {
            this.allUsers = res;
            this.users = res;
            this.isLoading = false;
        }).catch(error => {
            console.error(`error while get users. error: ${error}`);
            this.toastrNotificationService.errorsMessage("Get Users", "An error occurred!");
            this.isLoading = false;
        });
    }

    iconByRole(role: Role) {
        return role == Role.Admin ? 'person-fill' : 'person';
    }

    userSelectionChange(id: string) {
        if (this.selectedUsersIds.includes(id)) {
            this.selectedUsersIds = this.selectedUsersIds.filter(userId => userId != id);
        } else {
            this.selectedUsersIds.push(id);
        }
    }

    selectAllChange() {
        if (this.selectAllChecked) {
            this.selectedUsersIds = this.selectedUsersIds.filter(id => !this.users.map(user => user.id).includes(id));
        } else {
            this.users.forEach(user => {
                if (!this.selectedUsersIds.includes(user.id)) {
                    this.selectedUsersIds.push(user.id);
                }
            });
        }
    }

    openContextMenu(event: MouseEvent) {
        event.preventDefault();
        this.contextMenuPosition.x = event.clientX + 'px';
        this.contextMenuPosition.y = event.clientY + 'px';
        this.contextMenu.menu.focusFirstItem('mouse');
        this.contextMenu.openMenu();
    }

    editUser(user: User) {
        this.editedUsers = [user];
        this.showEditPopup = true;
    }

    editSelectedUsers() {
        this.editedUsers = this.users.filter(user => this.selectedUsersIds.includes(user.id));
        this.showEditPopup = true;
    }

    deleteUser(user: User) {
        this.deletedUsers = [user];
        this.titleForDeletePopup = "Delete User";
        this.contentMessageForDeletePopup = `Are you sure you want to delete the user ${user.name}?`;
        this.showDeletePopup = true;
    }

    deleteSelectedUsers() {
        this.deletedUsers = this.users.filter(user => this.selectedUsersIds.includes(user.id) && user.id != this.getCurrentUserId());
        let users_name = this.deletedUsers.map(user => user.name).join(", ");
        if (this.deletedUsers.length > 1) {
            this.contentMessageForDeletePopup = `Are you sure you want to delete the users ${users_name}?`;
            this.titleForDeletePopup = "Delete Users";
        }
        else {
            this.contentMessageForDeletePopup = `Are you sure you want to delete the user ${users_name}?`;
            this.titleForDeletePopup = "Delete User";
        }
        this.showDeletePopup = true;
    }

    updateUsers(userData: User) {
        this.userService.updateUsers(this.editedUsers?.map(user => user.id) || [], userData.projects_ids).then(res => {
            this.getUsers();
            this.showEditPopup = false;
            this.toastrNotificationService.successMessage("Update User", "Updated user successfully!");
        }).catch(error => {
            console.error(`error while update users. error: ${error}`);
            this.toastrNotificationService.errorsMessage("Update User", "An error occurred!");
        });
    }

    updateUserRoles(users: User[]) {
        this.userService.updateUserRoles(users).then(res => {
            this.getUsers();
            this.showEditPopup = false;
            this.toastrNotificationService.successMessage("Update User Role", "Updated user role successfully!");

        }).catch(error => {
            console.error(`error while update user roles. error: ${error}`);
            this.toastrNotificationService.errorsMessage("Update User Role", "An error occured!");
        });
    }

    closeDeleteUserPopup(statusReloadData: boolean) {
        if (statusReloadData) {
            this.deleteUsers();
        }
        this.showDeletePopup = false;
    }

    deleteUsers() {
        this.userService.deleteUsers(this.deletedUsers?.map(user => user.id) || []).then(res => {
            this.getUsers();
            this.showDeletePopup = false;
            this.deletedUsers = [];
            this.selectedUsersIds = [];
            this.toastrNotificationService.successMessage("Delete User", "Deleted user successfully!");

        }).catch(error => {
            console.error(`error while delete users. error: ${error}`);
            this.toastrNotificationService.errorsMessage("Delete User", "An error occurred!");
        });
    }

    onSearchChange(userToDisplay: User[]) {
        this.users = userToDisplay;
    }
}
