<app-header></app-header>
<div class="page agents">
    <div class="agents-wrap">
        <div class="title" >
            <div class="d-flex">
                <div>
                    <h2>Agents | <span class="count" >{{ agents.length + ' out of ' + agentsList.length }}</span></h2>
                    <button (click)="openCreateAgentPopup()" *ngIf="userRole === role.Admin">
                        + New Agent
                    </button>
                </div>
                <app-search *ngIf="agentsList && agentsList.length" [items]="agentsList" [searchKeys]="['id', 'name']"
                    (itemsListChange)="onSearchChange($event)"></app-search>
            </div>
        </div>
        <div class="agent" *ngFor="let agent of agents">
            <div class="left">
                <h3>{{agent.name}} ({{agent.id}})</h3>
                <div class="d-flex">
                    <div class="key">Project:</div>
                    <div class="value">{{agent.project.name ? agent.project.name : 'General'}}</div>
                </div>
                <div class="d-flex">
                    <div class="key">OS:</div>
                    <div class="value">{{agent.os}}</div>
                </div>
                <div class="d-flex">
                    <div class="key">Memory:</div>
                    <div class="value">{{agent.memory}}</div>
                </div>
                <div class="d-flex">
                    <div class="key">Compute:</div>
                    <div class="value">{{agent.compute}}</div>
                </div>
                <div class="d-flex">
                    <div class="key">Pre requirement software:</div>
                    <div class="value">{{agent.pre_requirement_software}}</div>
                </div>
                <div class="d-flex">
                    <div class="key">Libs:</div>
                    <div class="value">{{agent.libs}}</div>
                </div>
                <div class="d-flex">
                    <div class="key">Expected run time:</div>
                    <div class="value">{{agent.expected_run_time}}</div>
                </div>
            </div>
            <div id="rightDiv_{{agent.id}}" class="right">
                <div class="explantion">{{agent.explanation}}</div>
                <div>
                    <a class="href-custom" (click)="downloadTutorialDocument(agent.id)">User Guide</a>
                    <a class="href-custom ml-1" (click)="downloadMasterFile(agent.id)" *ngIf="agent.master_file">Master
                        File</a>
                </div>
                <h2>Downloads:</h2>
                <div class="flex-wrap">
                    <a class="download-button" [class.disabled-button]="!agent.agent_file_windows" (click)="downloadAgentFile(agent, filePurpose.agentWindows)"><img src="assets/Icons/windows.png" />Windows</a>
                    <a class="download-button" [class.disabled-button]="!agent.agent_file_linux" (click)="downloadAgentFile(agent, filePurpose.agentLinux)"><img src="assets/Icons/linux.png" />Linux</a>
                    <a class="download-button" [class.disabled-button]="!agent.agent_file_mac" (click)="downloadAgentFile(agent, filePurpose.agentMac)"><img src="assets/Icons/apple-logo.png" />MacOs</a>
                </div>
            </div>
            <div class="menu" *ngIf="userRole === role.Admin">
                <img src="assets/Icons/three-dots-vertical.svg" (click)="openContextMenu($event, agent)" />
            </div>
        </div>
        <div class="empty-wrap" *ngIf="(!agentsList || !agentsList.length) && (!generalAgents || !generalAgents.length)">
            <h2>You don’t have data agents.</h2>
            <h3>The agents be listed here.</h3>
            <img alt="empty agents list" src="assets/Icons/project-list-empty.png" />
        </div>
    </div>
 
    <ng-container *ngIf="generalAgents && generalAgents.length">
        <h2 class="general-title"><img src="assets/Icons/expand.svg" [class.collapse]="collapseMore" (click)="collapseMore = !collapseMore"/>More</h2>
        <div class="general-search">
            <app-search *ngIf="!collapseMore"
                [items]="generalAgents" 
                [searchKeys]="['name']"
                (itemsListChange)="onSearchOfGeneralChange($event)">
            </app-search>
        </div>
        <div class="general-agents" *ngIf="!collapseMore">
            <div class="agent" *ngFor="let agent of generalAgentsList" [routerLink]="['/', 'agent', agent.id]">
                <img class="icon" *ngIf="!agent.icon" src="./assets/Icons/agent-default.webp"/>
                <img class="icon" *ngIf="agent.icon" [src]="iconHost+'/'+agent.icon | secure | async"/>
                <div>
                    <div class="name">{{agent.name}}</div>
                    <div class="description">{{agent.explanation}}</div>
                </div>
            </div>
        </div>
    </ng-container>
    <a id="hidden-download-button" hidden [href]="fileLink" [download]="fileName"></a>
    <app-new-agent-popup *ngIf="showCreateAgentPopup" (closePopupEvent)="createAgentPopupClosed($event)"
        [agent]="editedAgent">
    </app-new-agent-popup>
    <app-delete-popup *ngIf="showDeleteAgentPopup" (closePopupEvent)="closeDeletePopup($event)" [title]="'Delete Agent'"
        [content]="deletePopupContent">
    </app-delete-popup>
</div>
<div style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
    [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu" [class]="'custom-panel-edit-agent-menu'">
    <ng-template matMenuContent let-agent="agent">
        <button *ngIf="!isGeneralContextMenu" mat-menu-item (click)="editAgent(agent)"><img
                src="assets/Icons/edit_30px.svg" /> Edit</button>
        <button mat-menu-item (click)="openDeletePopup(agent)"><img src="assets/Icons/delete.png" /> Delete</button>
    </ng-template>
</mat-menu>
<div class="custom-spinner" *ngIf="isLoading">
    <app-spinner></app-spinner>
</div>