import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OutputType } from 'src/app/models/OutputFile';
import Project from 'src/app/models/Project';
import ProjectSite from 'src/app/models/ProjectSite';
import Run, { Status, Type } from 'src/app/models/Run';
import { FileService } from 'src/app/services/file/file.service';
import { ProjectService } from 'src/app/services/project/project.service';
import { ProjectSitesService } from 'src/app/services/projectSites/project-sites.service';
import { RunService } from 'src/app/services/run/run.service';
import { ToastrNotificationsService } from 'src/app/services/toastr-notifications/toastr-notifications.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit {
  project: Project = new Project();
  showEditProjectSitesPopup: boolean = false;
  popupRunId: number;
  showLogsPopup: boolean = false;
  showRunProcessPopup: boolean = false;
  isLoading: boolean = false;
  numberRunsPagination: number = 1;
  collapseRuns = false;
  collapseFiles = false;
  showPredictPopup: boolean = false;

  get runType(): typeof Type {
    return Type;
  }

  get runStatus(): typeof Status {
    return Status;
  }

  get outputType(): typeof OutputType {
    return OutputType;
  }

  get chatModelLLM(): string {
    return environment.chatModelLLM;
  }

  constructor(private route: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService,
    private fileService: FileService,
    private toastrNotificationService: ToastrNotificationsService,
    private projectSitesService: ProjectSitesService,
    private runService: RunService,
    private toastrNotificationsService: ToastrNotificationsService) {
    this.route.params.subscribe(params => {
      this.project.id = params['id'];
    });
  }

  ngOnInit(): void {
    this.getProjectById();
  }

  getProjectById() {
    this.isLoading = true;
    this.projectService.getByProjectId(this.project.id).subscribe({
      next: (res: any) => {
        this.project = new Project(res.project);
        this.isLoading = false;
      },
      error: error => {
        this.isLoading = false;
        console.error('Error get project by id', error);
        this.toastrNotificationService.errorsMessage("Get project by id", "An error occurred!");
      }
    })
  }

  back() {
    this.router.navigate(["projects"])
  }

  retrieveFilesExist() {
    if (this.project.sites && this.project.sites.length) {
      let filesToRetrieve = this.project.sites.find(site => site.link_to_retrieve && site.link_to_retrieve != "");
      if (filesToRetrieve) {
        return false;
      }
    }
    return true;
  }

  closeEditProjectSitesPopup(event: boolean) {
    if (event) {
      this.getProjectById();
    }
    this.showEditProjectSitesPopup = false;
  }

  updateOutputFile(filesEvent: Event, projectSite: ProjectSite) {
    let files = (filesEvent.target as HTMLInputElement).files;
    if (files && files.length > 0) {
      let file = files[0];
      this.isLoading = true;
      this.fileService.uploadInputFileProjectSite(file, projectSite).subscribe({
        next: (res: any) => {
          this.getProjectById();
          this.toastrNotificationService.successMessage("Upload File", "Upload File successfully!");
          this.isLoading = false;
        },
        error: error => {
          this.isLoading = false;
          console.error('Error Upload File', error);
          this.toastrNotificationService.errorsMessage("Upload File", "An error occurred!");
        }
      });
    }
  }

  getDateStr(date: Date) {
    let dateArr = date.toDateString().split(' ');
    let timeArr = date.toTimeString().split(':');
    return `${dateArr[1]} ${dateArr[2]}, ${dateArr[3]} ${timeArr[0]}:${timeArr[1]}`;
  }

  deleteInputFile(projectSite: ProjectSite) {
    projectSite.input_file_path = null;
    this.isLoading = true;
    this.projectSitesService.update(projectSite).subscribe({
      next: (res: any) => {
        this.isLoading = false;
        this.getProjectById();
        this.toastrNotificationService.successMessage("Delete File", "Delete File successfully!");
      },
      error: error => {
        this.isLoading = false;
        console.error('Error delete input file', error);
        this.toastrNotificationService.errorsMessage("Delete File", "An error occurred!");
      }
    });
  }

  downloadInputFile(inputFilePath: string) {
    this.fileService.downloadFile(`/inputs/${inputFilePath}`, inputFilePath);
  }

  downloadOutputFile(run: Run, outputType?: OutputType) {
    this.runService.getOutputFileDownloadLink(run.id, outputType).then(res => {
      if (res) {
        let outputFileName = res.split('/outputs/').pop();
        this.fileService.downloadFile(res, `${outputFileName}`);
      }
    }).catch(error => {
      console.error(`error while get output file download link. error: ${error}`);
      this.toastrNotificationsService.errorsMessage("Download File", "An error occurred!");
    });
  }

  runProcess(type: Type) {
    if (type == Type.synthetic || type == Type.pyTorch || type == Type.RAG) {
      this.popupRunId = 0;
      this.showRunProcessPopup = true;
      this.runService.runProcess(this.project.id, type).subscribe({
        next: (res: any) => {
          if (res && res.run && res.run.id) {
            this.popupRunId = res.run.id;
          }
        },
        error: error => {
          this.showRunProcessPopup = false;
          this.getProjectById();
          console.error('Error Run Process', error);
          this.toastrNotificationService.errorsMessage("Run process", "An error occurred!");
        }
      });
    }

  }

  openRagWithChatGPT(runId: number) {
    this.runService.sendOutputFileToChatGPT(runId).subscribe({
      next: (res: any) => {
        if (res) {
          let url = environment.ragUrl + `/?files={"fileName":"${res.fileName}","fileServerName":"${res.fileServerName}"}`;
          window.open(url, '_blank');
          this.closeRunPopup();
        }
      },
      error: error => {
        console.error('Error send output file to ChatGPT', error);
        this.toastrNotificationService.errorsMessage("Open With " + environment.chatModelLLM, "An error occurred!");
      }
    });
  }

  predict(runId: number) {
    this.popupRunId = runId;
    this.showPredictPopup = true;
  }

  closeRunPopup() {
    this.getProjectById();
    this.showRunProcessPopup = false;
  }

  openPopupLog(runId: number) {
    this.showLogsPopup = true;
    this.popupRunId = runId;
  }

  retrieveFileProjectSite(projectSiteId: string) {
    this.isLoading = true;
    this.projectSitesService.retrieveInputFileProjectSite(projectSiteId).subscribe({
      next: (res: any) => {
        this.isLoading = false;
        this.getProjectById();
        this.toastrNotificationService.successMessage("Retrieve File", "Retrieve File successfully!");
      },
      error: error => {
        this.isLoading = false;
        console.error('Error Retrieve input file', error);
        this.toastrNotificationService.errorsMessage("Retrieve File", "An error occurred!");
      }
    });
  }

  retrieveAll() {
    this.isLoading = true;
    this.projectService.retrieveAllFiles(this.project.id).subscribe({
      next: (res: any) => {
        this.isLoading = false;
        this.getProjectById();
        this.toastrNotificationService.successMessage("Retrieve All", "Retrieve All successfully!");
      },
      error: error => {
        this.isLoading = false;
        console.error('Error Retrieve All', error);
        this.toastrNotificationService.errorsMessage("Retrieve All", "An error occurred!");
      }
    });
  }
}
