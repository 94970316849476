import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import Project, { PopupType } from 'src/app/models/Project';
import ProjectSite from 'src/app/models/ProjectSite';
import Site from 'src/app/models/Site';
import { ProjectService } from 'src/app/services/project/project.service';
import { SiteService } from 'src/app/services/site/site.service';
import { ToastrNotificationsService } from 'src/app/services/toastr-notifications/toastr-notifications.service';

@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.scss']
})
export class NewProjectComponent implements OnInit {
  constructor(private sanitizer: DomSanitizer, private projectService: ProjectService, private siteService: SiteService, private formBuilder: FormBuilder, private toastrNotificationService: ToastrNotificationsService) { }
  project: Project = new Project();
  @Output() createProjectEvent = new EventEmitter<Project>();
  @Output() closePopupEvent = new EventEmitter<boolean>();
  @Output() downloadMasterFileEvent = new EventEmitter<Project>();
  @Input() type = PopupType.create;
  @Input() projectId = "";
  projectSites: ProjectSite[] = [new ProjectSite()];
  sitesList: Site[] = [];
  fileToUpload: File | null = null;
  selectedSites: Site[] = [];
  projectForm: FormGroup;
  projectSiteForm: FormGroup
  showErrorRequiredMasterFile: Boolean = false;
  showErrorTypeMasterFile: Boolean = false;
  showErrorProjectNameAlreadyExists: Boolean = false;

  ngOnInit(): void {
    this.project.sites = [new ProjectSite()];
    this.getSites();
    if (this.type == PopupType.edit) {
      this.getProjectDetailsById()
    } else { this.createForm(); }
  }


  getProjectDetailsById() {
    this.projectService.getByProjectId(this.projectId).subscribe({
      next: (res: any) => {
        console.log(res);
        this.project = new Project(res.project);
        this.projectSites = this.project.sites;
        this.createForm();
      },
      error: error => {
        console.error('Error get project by id', error);
        this.toastrNotificationService.errorsMessage("Get Project By ID", "An error occurred!");
      }
    });
  }

  createForm() {
    this.projectForm = this.formBuilder.group({
      id: [this.project.id],
      name: [this.project.name, Validators.required],
      info: [this.project.info, Validators.required],
      contact_name: [this.project.contact_name, Validators.required],
      contact_phone: [this.project.contact_phone, [Validators.required, Validators.pattern("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$")]],
      contact_email: [this.project.contact_email, [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
      sites: this.formBuilder.array(this.buildProjectSitesGroup(this.project.sites))

    });
  }

  buildProjectSitesGroup(projectSites: ProjectSite[]) {
    return projectSites.map(projectSite => {
      return this.formBuilder.group({
        id:[projectSite.id],
        site_id: [projectSite.site_id, Validators.required],
        link_to_retrieve: [projectSite.link_to_retrieve, [Validators.pattern("^(sftp://|https://drive.google.com/file/d/).*")]]
      })
    })
  }

  get projectFormControl() {
    return this.projectForm.controls;
  }

  getSites() {
    this.siteService.getAll().subscribe({
      next: (res: any) => {
        this.sitesList = [];
        res.sites.forEach((site: any) => {
          this.sitesList.push(new Site(site));
        });
      },
      error: error => {
        console.error('Error get all sites', error);
        this.toastrNotificationService.errorsMessage("Get Sites", "An error occurred!");
      }
    });
  }

  closePopup() {
    this.closePopupEvent.emit(false);
  }

  initProjectByProjectForm() {
    this.project = {
      ...this.projectForm.getRawValue(),
      master_file_id: this.project.master_file_id,
    };
  }

  onSubmit() {
    this.projectForm.markAllAsTouched();
    this.showErrorTypeMasterFile = false;
    if (this.projectForm.valid && ((this.type == PopupType.create && this.fileToUpload != null) || (this.type == PopupType.edit && (this.fileToUpload != null || this.project.master_file_id ) ))) {  
  console.log('Your form data before change : ', this.project);
      let projectBeforeChange = { ...this.project };
      this.projectService.isProjectExists(this.projectForm.value.name).subscribe({
        next: (res: any) => {
          let isProjectExists = res.IsExists;
          if (this.type == PopupType.create) {
            if (!isProjectExists) {
              this.initProjectByProjectForm();
              this.createProject();
            }
            this.showErrorProjectNameAlreadyExists = isProjectExists;
          } else {
            if (projectBeforeChange.name.toLocaleLowerCase() === this.projectForm.value.name.toLocaleLowerCase() || !isProjectExists) {
              this.showErrorProjectNameAlreadyExists = false;
              this.initProjectByProjectForm();
              this.updateProject();
            } else {
              if (!isProjectExists) {
                this.initProjectByProjectForm();
                this.updateProject();
              }
              this.showErrorProjectNameAlreadyExists = isProjectExists;
            }
          }
        },
        error: (error: any) => {
          console.error('Error is project exists', error);
          this.toastrNotificationService.errorsMessage("Project - Is Already Exist", "An error occurred!");
        }
      });
      console.log('Your form data : ', this.projectForm.value);
    } else {
      if (!this.fileToUpload) {
        this.showErrorRequiredMasterFile = true
      }
    }
  }

  createProject() {
    console.log(this.project);
    if (this.fileToUpload) {
      this.projectService.uploadMasterFile(this.project, this.fileToUpload).subscribe({
        next: (res: any) => {
          let master_file_id = res.master_file_id;
          this.project.master_file_id = master_file_id;
          this.projectService.create(this.project).subscribe({
            next: (res: any) => {
              console.log(res);
              this.closePopupEvent.emit(true);
              this.toastrNotificationService.successMessage("Create Project", "Created project successfully!");
            },
            error: error => {
              console.error('Error create project', error);
              this.toastrNotificationService.errorsMessage("Create Project", "An error occurred!");
            }
          });
        },
        error: error => {
          console.error('Error upload master file', error);
          this.toastrNotificationService.errorsMessage("Upload - Master File", "An error occurred!");
        }
      });
    }
    else {
      console.error('Master File is required');
      this.toastrNotificationService.errorsMessage("Upload - Master File", "Master file is required!");
    }
  }

  updateProject() {
    if (this.fileToUpload) {
      this.projectService.uploadMasterFile(this.project, this.fileToUpload).subscribe({
        next: (res: any) => {
          let master_file_id = res.master_file_id;
          this.project.master_file_id = master_file_id;

          this.projectService.update(this.project).subscribe({
            next: (res: any) => {
              console.log(res);
              this.closePopupEvent.emit(true);
              this.toastrNotificationService.successMessage("Update Project", "Updated project successfully!");
            },
            error: error => {
              console.error('Error update project', error);
              this.toastrNotificationService.errorsMessage("Update Project", "An error occurred!");
            }
          });
        },
        error: error => {
          console.error('Error upload master file', error);
          this.toastrNotificationService.errorsMessage("Upload - Master File", "Master file is required!");
        }
      });
    }
    else {
      this.projectService.update(this.project).subscribe(res => {
        console.log(res);
        this.closePopupEvent.emit(true);
        this.toastrNotificationService.successMessage("Update Project", "Updated project successfully!");
      },
        err => {
          console.error('Error update project', err);
          this.toastrNotificationService.errorsMessage("Update Project", "An error occurred!");

        });
    }
  }

  updateMasterFile(filesEvent: Event) {
    this.showErrorRequiredMasterFile = false;
    this.showErrorTypeMasterFile = false;
    let files = (filesEvent.target as HTMLInputElement).files;
    if (files && files.length > 0) {
      let file = files[0];
      if (file.type != "text/csv") {
        this.showErrorTypeMasterFile = true;
        this.fileToUpload = null;
      } else {
        this.fileToUpload = file;
      }

    }
  }

  compareFn(site1: Site, site2: Site) {
    return site1 && site2 ? site1.id === site2.id : site1 === site2;
  }

  onTypingProjectName() {
    this.showErrorProjectNameAlreadyExists = false;
  }
}
