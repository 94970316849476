export default class Site {
    id: string = "";
    name: string = "";
    manager_name: string = "";
    manager_phone: string = "";
    manager_email: string = "";
    sftp_username: string;
    sftp_password: string;

    constructor(data?: any) {
        if (!data) return;
        if (data.id) this.id = data.id;
        if (data.name) this.name = data.name;
        if (data.manager_name) this.manager_name = data.manager_name;
        if (data.manager_phone) this.manager_phone = data.manager_phone;
        if (data.manager_email) this.manager_email = data.manager_email;
        if (data.sftp_username) this.sftp_username = data.sftp_username;
        if (data.sftp_password) this.sftp_password = data.sftp_password;
    }
}