import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { firstValueFrom } from 'rxjs';
import { FilePurpose } from 'src/app/models/Agents';

@Injectable({
  providedIn: 'root'
})
export class AgentsService {
  private path = "agents";
  constructor(private apiService: ApiService) { }

  getAll() {
    return this.apiService.getRequest(this.path)
  }

  getById(id: string) {
    return this.apiService.getRequest(`${this.path}/${id}`);
  }

  updateAgent(filesToUpload: { [key in FilePurpose]: File | null }, agentPayload: any) {
    const formData = this.getFormData(filesToUpload, agentPayload);
    return this.apiService.putRequest(`${this.path}/${agentPayload.id}`, formData);
  }

  async getAgentFile(agent_id: string, type: FilePurpose) {
    let url = "";
    if (type == FilePurpose.agentLinux)
      url = "/get-agent-linux-file/";
    else if (type == FilePurpose.agentWindows)
      url = "/get-agent-windows-file/";
    else if (type == FilePurpose.agentMac)
      url = "/get-agent-mac-file/";
    const response: any = await firstValueFrom(this.apiService.getRequest(this.path + url + agent_id))
    return response.downloadLink;
  }

  async getAgentMacFile(agent_id: string) {
    const response: any = await firstValueFrom(this.apiService.getRequest(this.path + "/get-agent-mac-file/" + agent_id))
    return response.downloadLink;
  }

  async getAgentWindowsFile(agent_id: string) {
    const response: any = await firstValueFrom(this.apiService.getRequest(this.path + "/get-agent-windows-file/" + agent_id))
    return response.downloadLink;
  }

  async getAgentLinuxFile(agent_id: string) {
    const response: any = await firstValueFrom(this.apiService.getRequest(this.path + "/get-agent-linux-file/" + agent_id))
    return response.downloadLink;
  }

  async getTutorialDocument(agent_id: string) {
    return await firstValueFrom(this.apiService.getRequest(this.path + "/get-tutorial-document/" + agent_id));
  }

  async getMasterFile(agent_id: string) {
    return await firstValueFrom(this.apiService.getRequest(this.path + "/get-master-file/" + agent_id));
  }

  getFormData(filesToUpload: { [key in FilePurpose]: File | null }, agentPayload: any) {
    const formData = new FormData();
    for (let key of Object.keys(agentPayload)) {
      formData.append(key, agentPayload[key]);
    }
    if (filesToUpload.master_file) {
      formData.set(FilePurpose.masterFile, filesToUpload.master_file);
    }
    if (filesToUpload.agent_file_linux) {
      formData.set(FilePurpose.agentLinux, filesToUpload.agent_file_linux);
    }
    if (filesToUpload.agent_file_windows) {
      formData.set(FilePurpose.agentWindows, filesToUpload.agent_file_windows);
    }
    if (filesToUpload.agent_file_mac) {
      formData.set(FilePurpose.agentMac, filesToUpload.agent_file_mac);
    }
    if (filesToUpload.installation_instruction) {
      formData.set(FilePurpose.userGuide, filesToUpload.installation_instruction);
    }
    if (filesToUpload.icon) {
        formData.set(FilePurpose.icon, filesToUpload.icon);
    }
    return formData;
  }

  createAgent(filesToUpload: { [key in FilePurpose]: File | null }, agentPayload: any) {
    const formData = this.getFormData(filesToUpload, agentPayload);
    return this.apiService.postRequestFormData(this.path, formData);
  }

  delete(id: string) {
    return this.apiService.deleteRequest(`${this.path}/${id}`);
  }
}
