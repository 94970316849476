import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OutputType } from 'src/app/models/OutputFile';
import Project from 'src/app/models/Project';
import { Type } from 'src/app/models/Run';
import { ProjectService } from 'src/app/services/project/project.service';

@Component({
  selector: 'app-view-project-popup',
  templateUrl: './view-project-popup.component.html',
  styleUrls: ['./view-project-popup.component.scss']
})
export class ViewProjectPopupComponent {
    @Input() projectId: string;
    @Output() closePopupEvent = new EventEmitter<boolean>();
    @Output() downloadOutputFileEvent = new EventEmitter<{ project: Project, outputType?: OutputType }>();
    project: Project;

    get runType(): typeof Type {
      return Type;
    }

    get outputType(): typeof OutputType {
        return OutputType;
    }

    constructor(private projectService: ProjectService) {}

    ngOnInit() {
        this.projectService.getByProjectId(this.projectId).subscribe({
            next: (res: any) => {
                this.project = new Project(res.project);
            }
        })
    }

    closePopup() {
        this.closePopupEvent.emit(true);
    }

    downloadOutputFile(outputType?: OutputType) {
        this.downloadOutputFileEvent.emit({ project: this.project, outputType });
    }
}
