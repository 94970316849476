import { Component } from '@angular/core';
import ContactInformation from 'src/app/models/ContactInformation';
import { OutputType } from 'src/app/models/OutputFile';
import Project from 'src/app/models/Project';
import { Type } from 'src/app/models/Run';
import { ConfigService } from 'src/app/services/config/config.service';
import { FileService } from 'src/app/services/file/file.service';
import { ProjectService } from 'src/app/services/project/project.service';
import { RunService } from 'src/app/services/run/run.service';
import { ToastrNotificationsService } from 'src/app/services/toastr-notifications/toastr-notifications.service';
import { UserManagementService } from 'src/app/services/userManagementService/user-management.service';

@Component({
    selector: 'app-user-projects',
    templateUrl: './user-projects.component.html',
    styleUrls: ['./user-projects.component.scss']
})
export class UserProjectsComponent {
    projects: Project[] = [];
    allProjects: Project[];
    showViewProjectPopup: boolean = false;
    projectIdForView: string;
    contactInformation: ContactInformation = new ContactInformation();
    numberUserProjectsPagination: number = 1;
    isLoading: boolean = false;

    get runType(): typeof Type {
      return Type;
    }

    get outputType(): typeof OutputType {
      return OutputType;
    }

    constructor(
        public projectService: ProjectService,
        public userManagementService: UserManagementService,
        private runService: RunService,
        private fileService: FileService,
        private configService: ConfigService,
        private toastrNotificationsService: ToastrNotificationsService
    ) { }

    ngOnInit() {
        this.getProjects();
        this.getContactInformation();
    }

    getProjects() {
        this.isLoading = true;
        let current_user = this.userManagementService.getCurrentUser();
        if (current_user != null) {
            this.projectService.getProjectsByUserId(current_user.id).then(res => {
                this.allProjects = res;
                this.projects = res;
                this.isLoading = false;
            }).catch(error => {
                console.error(`error while get projects. error: ${error}`);
                this.isLoading = false;
                this.allProjects = [];
            });
        }
        else {
            this.isLoading = false;
        }
    }

    getContactInformation() {
        this.configService.getContactInformation().subscribe({
            next: (res: any) => {
                this.contactInformation = new ContactInformation(res.contactInformation);
            },
            error: error => {
                console.error(`error while get contact information. error: ${error}`);
            }
        });
    }

    onSearchChange(projectsToDisplay: Project[]) {
        this.projects = projectsToDisplay;
    }

    downloadOutputFileListener(event: { project: Project, outputType?: OutputType }) {
        this.downloadOutputFile(event.project, event.outputType);
    }

    downloadOutputFile(project: Project, outputType?: OutputType) {
        this.runService.getOutputFileDownloadLink(project.lastSuccessRunId, outputType).then(res => {
            let outputFileName = res.split('/outputs/').pop();
            this.fileService.downloadFile(res, outputFileName);
        }).catch(error => {
            console.error(`error while get output file download link. error: ${error}`);
            this.toastrNotificationsService.errorsMessage("Download File", "An error occurred!");
        });
    }

    openViewPopup(projectId: string) {
        this.projectIdForView = projectId;
        this.showViewProjectPopup = true;
    }
}
