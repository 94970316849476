import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Type } from 'src/app/models/Run';
import { FileService } from 'src/app/services/file/file.service';
@Component({
  selector: 'app-federated-learning-predict-popup',
  templateUrl: './federated-learning-predict-popup.component.html',
  styleUrls: ['./federated-learning-predict-popup.component.scss']
})
export class FederatedLearningPredictPopupComponent {
  @Input() type: Type = Type.pyTorch;
  @Input() runId: number;
  @Output() closePopupEvent = new EventEmitter<boolean>();
  fileToPredict: File | null = null;
  downloadFilePath: string = "";
  loadingNumberPercentStatus: number = 0;
  loadingInterval: NodeJS.Timeout;
  shoeErrorRunPredict: boolean = false;
  errorMessage: string = "";

  constructor(private fileService: FileService) {}

  closePopup() {
    this.closePopupEvent.emit(false);
  }
 
  runPredict(){
    if (!this.fileToPredict) {
      console.error('No file uploaded');
      return;
    }
   this.resetFields();
    this.fileService.predictFile(this.fileToPredict, this.runId, this.type).subscribe({
      next: (res: any) => {
        this.loadingNumberPercentStatus = 100;
        clearInterval(this.loadingInterval);
        this.downloadFilePath = res.downloadLink;
      },
      error: error => {
        clearInterval(this.loadingInterval);
        this.shoeErrorRunPredict = true;
        this.errorMessage = error.error;
      }
    });
    this.setLoadingNumberPercent();
  }

 
  updatePredictFile(filesEvent: Event) {
    let files = (filesEvent.target as HTMLInputElement).files;
    if (files && files.length > 0) {
      let file = files[0];
      this.fileToPredict = file;
      this.resetFields();
    }
  }

  downloadFile(){
    const name = this.downloadFilePath.split('/').pop();
    this.fileService.downloadFile(this.downloadFilePath, name);
  }
 
  setLoadingNumberPercent(){
    this.loadingInterval = setInterval(() => {
      if (this.loadingNumberPercentStatus < 100) {
        this.loadingNumberPercentStatus += 1;
      } else {
        clearInterval(this.loadingInterval);
      }
    }, 100);
  }

  resetFields(){
    this.loadingNumberPercentStatus = 0;
    this.shoeErrorRunPredict = false;
    this.errorMessage = "";
    this.downloadFilePath =  ""; 
  }
}