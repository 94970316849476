<div class="action-btn">
  <button class="white" mat-button [matMenuTriggerFor]="runs"><img class="svg-icon" src="assets/Icons/runs.svg"/><span>Consolidate</span><img src="assets/Icons/down-arrow.png" /></button>
  <mat-menu #runs="matMenu" [class]="'custom-panel-action-menu'">
    <button mat-menu-item (click)="selectRun(runType.synthetic)"><img class="svg-icon" src="assets/Icons/Synthetic.svg"/>{{runType.synthetic}}</button>
    <button mat-menu-item  (click)="selectRun(runType.RAG)"><img class="svg-icon" src="assets/Icons/rag.svg"/>{{runType.RAG}}</button>
    <button mat-menu-item [matMenuTriggerFor]="federatedLearning"><img class="svg-icon" src="assets/Icons/federatedLearning.svg"/>Federated Learning <img class="right-arrow" src="assets/Icons/right.png" /></button>
  </mat-menu>

  <mat-menu #federatedLearning="matMenu" [class]="'custom-panel-federated-learning-menu'">
    <button mat-menu-item disabled="true" class="disable-button" (click)="selectRun(runType.tensorflow)"><img class="svg-icon" src="assets/Icons/tensorflow.svg"/>{{runType.tensorflow}}</button>
    <button mat-menu-item (click)="selectRun(runType.pyTorch)"><img class="svg-icon" src="assets/Icons/pytorch.svg"/>{{runType.pyTorch}}</button>
    <button mat-menu-item disabled="true" class="disable-button" (click)="selectRun(runType.keras)"><img class="svg-icon" src="assets/Icons/keras.svg"/>{{runType.keras}}</button>
  </mat-menu>
</div>