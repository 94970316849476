import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import Project, { PopupType } from 'src/app/models/Project';
import { MasterFileService } from 'src/app/services/master-file/master-file.service';
import { ProjectService } from 'src/app/services/project/project.service';
import { ToastrNotificationsService } from 'src/app/services/toastr-notifications/toastr-notifications.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  projectsList: Project[];
  projects: Project[];
  showProjectPopup: boolean = false;
  showDeleteProjectPopup: boolean = false;
  projectPopupType: PopupType = PopupType.create;
  projectId: string;
  isLoading: boolean = true;
  masterFileLink: SafeResourceUrl | null;
  masterFileName: string;
  contentMessageForDeletePopup: string = ""
  numberProjectsPagination: number = 1;

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private projectService: ProjectService,
    private toastrNotificationService: ToastrNotificationsService,
    private masterFileService: MasterFileService
  ) { }

  ngOnInit(): void {
    this.initProjects();
  }

  initProjects(): void {
    this.projectService.getAll().subscribe({
      next: (res: any) => {
        if (res && res.projects) {
          this.projectsList =  res.projects.map((project: any) => new Project(project));
          this.projects = this.projectsList;
        }
        this.isLoading = false;
      },
      error: error => {
        console.error('Error get projects', error);
        this.toastrNotificationService.errorsMessage("Get Projects", "An error occurred!");
        this.isLoading = false;
      }
    });
  }

  openCreateProjectPopup() {
    this.showProjectPopup = true;
    this.projectPopupType = PopupType.create;
  }

  deleteProject(projectId: string) {
    this.projectService.delete(projectId).subscribe({
      next: (res: any) => {
        this.initProjects();
        this.toastrNotificationService.successMessage("Delete Project", "Deleted project successfully!");
      },
      error: error => {
        console.error('Error delete project', error);
        this.toastrNotificationService.errorsMessage("Delete Project", "An error occurred!");
      }
    });
  }

  closeProjectPopup(statusReloadData: boolean) {
    if (statusReloadData) {
      this.initProjects();
    }
    this.showProjectPopup = false;
  }

  goToProjectPage(projectName: string) {
    this.router.navigate(['/project', projectName]);
  }

  openEditProjectPopup(projectId: string) {
    this.projectId = projectId;
    this.showProjectPopup = true;
    this.projectPopupType = PopupType.edit;
  }

  openDeleteProjectPopup(projectId: string) {
    this.projectId = projectId;
    let current_project = this.projects.find(p => p.id == projectId);
    let project_name = current_project?.name;
    this.contentMessageForDeletePopup = `Are you sure you want to delete the \"#${projectId} - ${project_name}\" project?`;
    this.showDeleteProjectPopup = true;
  }

  closeDeleteProjectPopup(statusReloadData: boolean) {
    if (statusReloadData) {
      this.deleteProject(this.projectId);
    }
    this.showDeleteProjectPopup = false;
  }

  onSearchChange(projectsToDisplay: Project[]) {
    this.projects = projectsToDisplay;
  }

  getMasterFileContent(project: Project) {
    this.masterFileService.getById(project.master_file_id).then(master_file => {
      const blob = new Blob([new Uint8Array((master_file.content as any).data).buffer]);
      this.masterFileLink = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
      this.masterFileName = `${project.name.replace(/ /g, '_')}.csv`;
      setTimeout(() => document.getElementById("hidden-download-button")?.click(), 5);
    }).catch(error => {
      console.error(`error while get master file content. error: ${error}`);
      this.toastrNotificationService.errorsMessage("Master File", "An error occurred!");
    });
  }
}
